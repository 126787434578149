import { SimpleForm, SimpleShowLayout } from 'react-admin';
import { styled } from '@mui/system';
import { backgroundColor } from '../../Theme/theme';

export const FormTransparent = styled(SimpleForm)({
  backgroundColor: backgroundColor,
  display: 'flex',
  flexDirection: 'row',
  '& .MuiCardContent-root': {
    width: '75%',
    paddingLeft: '5rem'
  },
  
});

export const ShowTransparent = styled(SimpleShowLayout)({
  backgroundColor: backgroundColor,
  form: {
    backgroundColor: 'transparent'
  }
});