import React, { useState, cloneElement, useEffect, useContext } from 'react';
import { TitleBox, WizardBox, WizardBoxx, WizardButton, WizardDisabledButton, WizardEmpty, WizardImg, WizardLine } from './StyledComponets.js/WizardStyled';
import { Box, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { BiHome, BiUser } from 'react-icons/bi';
import { theme } from '../../Theme/theme';
import { DispatchErrorContext } from '../../Context/menuContext';

export const Wizard = ({ currentTab, tabs, handleTab }) => {

  const {errors} = useContext(DispatchErrorContext);

  const [maxTabs, setMaxTabs] = useState(currentTab);

  const xs = useMediaQuery('(max-width:600px)');
  const ls = useMediaQuery('(max-width:900px)');
  const xl = useMediaQuery('(min-width:1200px)');
  const xxl = useMediaQuery('(min-width:1500px)');

  const handleSelectedTab = (i) => {
    if(errors.length === 0){
      handleTab(i);
    }
  };

  useEffect(() => {
    currentTab > maxTabs ? setMaxTabs(currentTab) : null;
  }, [currentTab]);


    
  return (
    <>
      <WizardBox xs={xs} cols={tabs.length}>
        {
          !xs ? (
            tabs.map((e, i) => (
              !e.disabled ? (
                <>
                  <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start', position: 'relative'}}>
                    <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'auto', height: '150px'}}>
                      <WizardButton
                        onClick={() => handleSelectedTab(i)}
                        disabled={i <= maxTabs ? false : true}
                        enabled={(currentTab !== i && currentTab <= maxTabs) ? true : false}
                        key={i}>
                        {e && cloneElement(e?.icon, { style: { fontSize: '25px', color: (currentTab !== i && currentTab <= maxTabs) ? '#808e95' : '#fff' }, })}
                      </WizardButton>
                      <Tooltip title={e.label} placement="bottom">
                        <Box style={{ height: '30px', position: 'absolute', bottom: 0, textAlign: 'center',}}>
                          
                          <Typography
                            sx={{
                              fontSize: '12px',
                              textTransform: 'uppercase',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: xxl ? '120px' : xl ? '100px' : '90px',
                              whiteSpace: 'nowrap',
                              color: (currentTab !== i && currentTab <= maxTabs) ?
                                '#7E7E7E' :
                                '#000'
                            }}
                          >
                            {e.label}
                          </Typography>
                          
                        </Box>
                      </Tooltip>
                    </Box>
                    {
                      tabs?.indexOf(e) < (tabs.length - 1) && !tabs[i+1].disabled ? (
                                                
                        <WizardLine ls={ls} length={tabs.length} xs={xs} disabled={i <= (maxTabs - 1) ? false : true} />
                                                
                      ) : null
                    }
                    {
                      tabs?.indexOf(e) < (tabs.length - 1) && !tabs[i+1].disabled ? (
                                                
                        <WizardLine displayNone length={tabs.length} position ls={ls} xs={xs} disabled={i <= (maxTabs - 1) ? false : true} />
                                                
                      ) : null
                    }
                  </Box>

                </>
              ) : (
                <>
                </>
              )
            )
                           
            )) : (
            <>
              {currentTab === 0 ? (<WizardEmpty />) : (
                <>
                  <WizardButton onClick={() => handleTab(currentTab - 1)} small disabled={(currentTab - 1) <= maxTabs ? false : true} >
                    {tabs[currentTab - 1] && cloneElement(tabs?.[currentTab - 1].icon, { style: { fontSize: '25px', color: 'white' } })}
                  </WizardButton>
                  {
                    tabs.indexOf(currentTab) < (tabs.length - 1) ? (
                      <WizardLine xs={xs} disabled={(currentTab - 1) <= (maxTabs - 1) ? false : true} />
                    ) : null
                  }
                </>
              )}
              <WizardButton onClick={() => handleTab(currentTab)} selected >
                {tabs[currentTab] && cloneElement(tabs?.[currentTab].icon, { style: { fontSize: '25px', color: 'white' } })}
              </WizardButton>
              {
                tabs.indexOf(currentTab) <= (tabs.length - 1) ? (
                  <WizardLine xs={xs} disabled={currentTab <= (maxTabs - 1) ? false : true} />
                ) : null
              }
              {
                tabs[currentTab + 1] && (
                  <WizardButton onClick={() => handleTab(currentTab + 1)} small disabled={(currentTab + 1) <= maxTabs ? false : true} >
                    {tabs[currentTab + 1] && cloneElement(tabs?.[currentTab + 1]?.icon, { style: { fontSize: '25px', color: 'white' } })}
                  </WizardButton>
                )

              }
            </>
          )
        }
      </WizardBox>
    </>
  );
};

