import { Box, Grid, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { List, Datagrid, TextField, CreateButton,
  TopToolbar, ExportButton, TextInput, useRedirect, FunctionField, usePermissions
} from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { merchantContext, merchantSelected } from '../../DataProvider';
import { createNotification, dateFormat } from '../helpers/helpFunctions';
import logoDecidir from '../../Assets/Images/paywayLogo.png';
import logoMP from '../../Assets/Images/mpLogoCrop.png';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Typography } from '@mui/material';



const clientFilters = [
  <TextInputIcon
    source="q" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
];

const MerchantsList = (props) => {
  const redirect = useRedirect();
  const url = window.location.href.split('?')[1];
  
  useEffect(() => {
    if(url && url.includes('code=')){
      let urlCode = window.location.href.split('?')[1];
      redirect(`https://asociate.club/#/merchants/?${urlCode}`);
    }
  },[]);
  return (
    <>
    </>
  );
};

export default MerchantsList;

export const MerchantListActions = ({ resource }) => {

  const {permissions} = usePermissions();
  console.log(permissions);
  return (
    <TopToolbar>
      {
        (permissions == 'superadmin' || permissions == 'owner') && (
          <CreateButton />
        )
      }
    </TopToolbar>
  );
};
