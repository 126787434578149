import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { required, useDataProvider, TextInput, useRecordContext } from 'react-admin';
import SectionForm from '../../../Components/Form/SectionForm';
import { getToken } from '../../helpers/helpFunctions';
import { Box, Checkbox, Typography, Button, TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import VentaPlanesCadena from './../Edit/PlanesCadena';
import { TiTick } from 'react-icons/ti';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { AiOutlineWarning } from 'react-icons/ai';
import { merchantCadena, merchantContext } from '../../../DataProvider';
import { WarningComponent } from '../../../Components/CustomFields/WarningComponent';


export const credentialsEdit = (record) => {
  
  const {getMerchant} = useContext(merchantContext);
  const role = localStorage.getItem('permissions');
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [editCred, setEditCred] = useState(false);
  const [editCredDecidir, setEditCredDecidir] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageDecidir, setMessageDecidir] = useState(false);
  const [credentials, setCredentials] = useState(record?.credentials ? record?.credentials : {
    mercadopago: {
      active: false
    },
    decidir: {
      active: false
    },
    mp_marketplace: {
      active: false
    }
  });

  const urlCode = window.location.href.split('?')[1];
  

  const mercadoPagoAT = (e) => {
    let value = e.target.value;
    let codeValue = value.split('-');
    setCredentials({...credentials, 
      mercadopago: ({
        ...credentials?.mercadopago,
        access_token: e.target.value,
        application_id: codeValue[codeValue.length - 1]
      })
    });
  };
  

  const mercadoPagoPK = (e) => {
    setCredentials({...credentials, 
      mercadopago: ({
        ...credentials?.mercadopago,
        public_key: e.target.value,
        
      })
    });
  };

  const decidirAT = (e) => {
    let value = e.target.value;
    let codeValue = value.split('-');
    setCredentials({...credentials, 
      decidir: ({
        ...credentials.decidir,
        site_id: value.toString()
      })
    });
  };

  const decidirHandler = (e) => {
    let value = e.target.checked;
    setMessageDecidir(!e.target.checked);
    setCredentials({...credentials, 
      decidir: ({
        ...credentials.decidir,
        active: value,
      })
    }); 
  };

  const mpHandler = (e) => {
    setMessage(!e.target.checked);
    let value = e.target.checked;
    setCredentials({...credentials, 
      mercadopago: ({
        ...credentials.mercadopago,
        active: value,
      }),
      mp_marketplace: ({
        ...credentials.mp_marketplace,
        active: value
      })
    }); 
  };

  useEffect(()=> {
    if(role == 'owner'){
      const token = getToken();
      dataProvider.getOneFilter(`users?filter={'email': '${token.email}'}`).then(i => setData(i.data[0]));
    }else{
      dataProvider.getOneFilter('users?filter={"role": "owner"}').then(i => setData(i.data));
    }
  }, []);

  const cadenaURL = window.location.href.includes(merchantCadena);
  const roleSuperOwner = role === 'superadmin' || role === 'owner';

  const dataArray = [
    {
      component: <> 
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
          <Box
            style={{
              color: `${credentials?.mercadopago?.active ? '#22577E' : '#cccab5' }`,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontWeight: 'normal',
              backgroundColor:'#FFFFFF' }}

          >
            <Typography fontSize={'1.1em'} style={{textTransform: 'uppercase', fontWeight: '600', margin: '10px', color: 'black'}}>Mercado Pago</Typography>
            {credentials?.mercadopago?.active ? (

              <Box sx={{ borderRadius: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', margin: '0 100px 0 20px'}}>
                <Typography style={{fontSize: '0.7em',textTransform: 'uppercase', color: '#90cc00', fontWeight: '600', border: '1px solid #90cc00', borderRadius: '15px', padding: '2px 5px'}}>Activado</Typography>
              </Box>
            ) : (
              <Typography style={{fontSize: '0.7em',textTransform: 'uppercase', padding: '2px 5px', color: '#ff867c',border: '1px solid #ff867c', borderRadius: '15px', fontWeight: '400', margin: '0 100px 0 20px'}}>Desactivado</Typography>
            )
            }
        
          </Box>
          <Tooltip title={!credentials?.mp_marketplace?.access_token ? 'Debes habilitarte para la vinculación con la red de SportClub para poder activar el procesador de pago de mercado pago' : ''}>
            <Switch
              variant="outlined"
              source='credentials.mercadopago.active'
              // label="Activar facturacion"
              disabled={!credentials?.mp_marketplace?.access_token}
              defaultChecked={credentials?.mercadopago.active}
              // checked={activeMercadoPago}
              onClick={mpHandler}
              sx={{mr: '25px'}}
            />   
          </Tooltip>
        </Box>
        {window.location.href.split('?')[1]?.includes('code=') && (
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <WarningComponent sx={{width: '50%', margin: '0 20px', }} fontSize=".8em">Es importante guardar el código de marketplace para poder habilitarse a la venta de planes.</WarningComponent>
            <TextField variant="outlined" disabled value={window.location.href.split('?')[1]?.split('=')[1].split('&')[0]} />
          </Box>
        )}
        <div style={{borderBottom: '1px solid #9ea7aa', margin: '20px 0'}}></div>
        { record && !editCred && <Typography style={{color: '#22577E', cursor: 'pointer', fontSize: '0.8em', marginLeft: '10px'}}onClick={() => setEditCred(true)}>Editar Credenciales</Typography>}
        { 
          (!record || editCred || (!record?.credentials?.mercadopago?.access_token && !record?.credentials?.mercadopago?.access_token && credentials?.mercadopago?.active === true)) && (
            <>
              <Box sx={{border: (record && !record?.credentials?.mp_marketplace?.access_token) ? '1px solid #ffc107' : '', borderRadius: '5px'}}>
                {record && !record?.credentials?.mp_marketplace?.access_token && (
                  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'start', width: 'auto', height: '100%', minHeight: '90px', justifyContent: 'flex-start', }}>
                    <Box sx={{ display: 'flex', height: 'auto',  minHeight: '70px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffca28', borderRadius: '5 0px 0 0px'}}>
                      <AiOutlineWarning size={40} style={{margin: '0 20px'}} />
                    </Box>
        
                    <Box sx={{ display: 'flex', paddingLeft: '10px', flexDirection: 'column', height: 'auto', minHeight: '70px', alignItems: 'flex-start', width: '100%', justifyContent: 'center', backgroundColor: '#ffecb3', borderRadius: '0px 5px 0 0px'}}>
                      <Typography variant="h7" textTransform={'uppercase'} fontWeight={600} marginLeft={'10px'}>Importante</Typography>
                      <Typography  style={{fontSize: '0.9em', marginLeft: '10px'}}>Al editar las credenciales, se pueden ver afectados los pagos futuros</Typography>
                    </Box>
                  </Box>
                )}
                <Tooltip placement="bottom" title={`${record?.credentials?.mp_marketplace?.access_token ? 'No se pueden editar las credenciales una vez habilitado para vender planes cadena. Si desea editar estas credenciales, comunicarse con soporte@miclub.helpscoutapp.com' : ''}`}>
                  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '10px 10px', transition: 'ease-in-out'}}>
                    <TextField 
                      validate={[required('Falta el campo del Token')]}
                      required={credentials?.mercadopago?.active}
                      variant="outlined" 
                      size="small" 
                      fullWidth
                      disabled={record?.credentials?.mp_marketplace?.access_token}
                      source='credentials.mercadopago.access_token' 
                      onChange={(e) => mercadoPagoAT(e)} 
                      defaultValue={record?.credentials?.mercadopago?.access_token} 
                      label='Token de Acceso' 
                    />
                    <TextField 
                      validate={[required('Falta el campo de la clave pública')]} 
                      variant={'outlined'}
                      fullWidth
                      required={credentials?.mercadopago?.active}
                      size="small"
                      disabled={record?.credentials?.mp_marketplace?.access_token}
                      source='credentials.mercadopago.public_key'
                      onChange={(e) => mercadoPagoPK(e)} 
                      defaultValue={record?.credentials?.mercadopago?.public_key} 
                      label='Clave Pública' />
                  </Box>
                </Tooltip>
              </Box>
              <Box sx={{
                marginTop: '20px'
              }}
              >
                {record && <VentaPlanesCadena.VentaPlanesCadena record={record} />}
              </Box>
            </>
          )
        }
        {
          message && (
            <>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'start', width: 'auto', height: '100%', minHeight: '90px', justifyContent: 'flex-start'}}>
                <Box sx={{ display: 'flex', height: 'auto',  minHeight: '70px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffca28', borderRadius: '5px 0px 0 5px'}}>
                  <AiOutlineWarning size={40} style={{margin: '0 20px'}} />
                </Box>
        
                <Box sx={{ display: 'flex', paddingLeft: '10px', flexDirection: 'column', height: 'auto', minHeight: '70px', alignItems: 'flex-start', width: '100%', justifyContent: 'center', backgroundColor: '#ffecb3', borderRadius: '0px 5px 0 0px'}}>
              
                  <Typography  style={{fontSize: '0.8em', marginLeft: '10px'}}>Al desactivar las credenciales de MercadoPago, no se cobraran a los socios activos del Negocio.</Typography>
                </Box>
              </Box>
         
            </>
          )
        }
      </>,
      custom: true,
      xs: 12
    },{
      custom: true,
      component: <> 
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '20px 0'}}>
          <Box
            style={{
              color: `${credentials?.decidir?.active ? '#22577E' : '#cccab5' }`,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontWeight: 'normal',
              backgroundColor:'#FFFFFF' }}

          >
            <Typography fontSize={'1.1em'} style={{textTransform: 'uppercase', fontWeight: '600', margin: '10px', color: 'black'}}>Decidir</Typography>
            {credentials?.decidir?.active ?
              (
                <Box sx={{ borderRadius: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', margin: '0 100px 0 20px'}}>
                  <Typography style={{fontSize: '0.7em',textTransform: 'uppercase', color: '#90cc00', fontWeight: '600', border: '1px solid #90cc00', borderRadius: '15px', padding: '2px 5px'}}>Activado</Typography>
                </Box>
              )
              : (
                <Typography style={{fontSize: '0.7em',textTransform: 'uppercase', padding: '2px 5px', color: '#ff867c',border: '1px solid #ff867c', borderRadius: '15px', fontWeight: '400', margin: '0 100px 0 20px'}}>Desactivado</Typography>
              )
            }
        
          </Box>
          <Switch
            variant="outlined"
            source='credentials.decidir.active'
            label="Activar facturacion"
            defaultChecked={credentials?.decidir?.active}
            // checked={activeDecidir}
            onClick={(e) => {decidirHandler(e);}}
            sx={{mr: '25px'}}
          />               
        </Box>
        <div style={{borderBottom: '1px solid #9ea7aa', margin: '20px 0'}}></div>
        { (record && !editCredDecidir && (getMerchant()?.id !== merchantCadena) && !cadenaURL) && <Typography style={{color: '#22577E', cursor: 'pointer', fontSize: '0.8em', marginLeft: '10px'}} onClick={() => setEditCredDecidir(true)}>Editar Credenciales</Typography>}
        { 
          (!record || editCredDecidir) && 
        <Box sx={{border: editCredDecidir ? '1px solid #ffc107' : '', borderRadius: '5px'}}>
          {editCredDecidir && (
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'start', width: 'auto', height: '100%', minHeight: '90px', justifyContent: 'flex-start', }}>
              <Box sx={{ display: 'flex', height: 'auto',  minHeight: '70px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffca28', borderRadius: '5 0px 0 0px'}}>
                <AiOutlineWarning size={40} style={{margin: '0 20px'}} />
              </Box>
        
              <Box sx={{ display: 'flex', paddingLeft: '10px', flexDirection: 'column', height: 'auto', minHeight: '70px', alignItems: 'flex-start', width: '100%', justifyContent: 'center', backgroundColor: '#ffecb3', borderRadius: '0px 5px 0 0px'}}>
                <Typography variant="h7" textTransform={'uppercase'} fontWeight={600} marginLeft={'10px'}>Importante</Typography>
                <Typography  style={{fontSize: '0.9em', marginLeft: '10px'}}>Al editar las credenciales, se pueden ver afectados los pagos futuros</Typography>
              </Box>
            </Box>
          )}
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '10px 10px', transition: 'ease-in-out'}}>
            <TextField 
              validate={[required('Falta el campo del Token')]}
              required={credentials?.decidir?.active ? [required('Es necesario ingresar el site id para decidir.')] : null}
              variant="outlined" 
              size="small" 
              type="number"
              disabled={!roleSuperOwner}
              source='credentials.decidir.site_id' 
              onChange={(e) => decidirAT(e)} 
              defaultValue={record?.credentials?.decidir?.site_id} 
              label='Site ID' 
              xs={6}
            />
          </Box>
        </Box>
        }
        {
          messageDecidir && (
            <>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'start', width: 'auto', height: 'auto', minHeight: '90px', justifyContent: 'flex-start', marginTop: '20px'}}>
                <Box sx={{ display: 'flex', height: 'auto',  minHeight: '70px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffca28', borderRadius: '5px 0px 0 5px'}}>
                  <AiOutlineWarning size={40} style={{margin: '0 20px'}} />
                </Box>
        
                <Box sx={{ display: 'flex', paddingLeft: '10px', flexDirection: 'column', height: 'auto', minHeight: '70px', alignItems: 'flex-start', width: '100%', justifyContent: 'center', backgroundColor: '#ffecb3', borderRadius: '0px 5px 0 0px'}}>
              
                  <Typography  style={{fontSize: '0.8em', marginLeft: '10px'}}>Al desactivar las credenciales de Decidir, no se cobraran a los socios activos del Negocio.</Typography>
                </Box>
              </Box>
         
            </>
          )
        }
      </>,
      xs: 12
    },
    {
      source: 'credentials',
      value: credentials,
      validate: {
        validate: {
          decidirReq: (e) => e.decidir.active && !e.decidir.site_id ? 'Es necesario ingresar el site ID si se quiere activar Decidir' : undefined,
          mpReq: (e) => e.mercadopago.active && (!e.mercadopago.access_token || !e.mercadopago.public_key) ? 'Es necesario ingresar las credenciales si se quiere activar Mercado Pago' : undefined
        }
      },
      displayNone: true
    },
  ];

  return dataArray;
};