import { Typography } from '@mui/material';
export const stepsitos =(permis,merchant)=>{
  const isSuperAdmin = permis === 'superadmin' || permis === 'owner';
  const isAdmin = permis === 'admin';
  const isOperator = permis === 'operador';
  const isOnlyAccess = permis === 'accesos';
  const isAccessPoint = permis === 'molinetes';
  const steps = 
    [
      {
        content: (
          <Typography variant="body1" fontWeight={400} gutterBottom>
            
          </Typography>
        ),
        title: (
          <Typography variant="h6" fontWeight={500} sx={{ pt: 2 }}>
            Bienvenido al panel de control de BuenClub 🏋️
          </Typography>
        ),
        placement: 'center',
        target: 'body',
      },
    ];
  const conditionalSteps = [
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Si sos gestor de más de un comercio, podrás seleccionarlos uno a uno en esta sección. Todas las funcionalidades de la plataforma se adaptarán al negocio seleccionado.
        </Typography>
      ),
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Selector de negocio
        </Typography>
      ),
      local: { skip: <strong>SKIP</strong> },
      placement: 'auto',
      target: '#selectMerchant',
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Visualizá las métricas más relevantes de tu negocio.
        </Typography>
      ),
      placement: 'auto',
      target: '#dashboard',
      isFixed: true,
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Dashboard
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Podrás ver el listado de socios asociados en tu negocio, con el detalle
            correspondiente al plan y fecha de alta.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.socios',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Socios
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Maximizá tus oportunidades de ventas. Quedarán registrados en esta
            solapa los socios que iniciaron una compra y la abandonaron.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.abandoned',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Prospectos
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Monitoreá y creá accesos de socios a tu sede.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.accesos',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Accesos
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Te permitirá realizar búsquedas por DNI, apellido, email de socios que
            pertenecen a la comunidad SportClub.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.busqueda',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Red SportClub
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Mantené un control de aquellos socios que abonan su plan en efectivo y
            tienen deuda. Luego las podés gestionar y tomar acciones ingresando al
            perfil del socio.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.efectivo',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Efectivo
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Mantené un registro de aquellos socios que abonan su plan con tarjeta y
            no se les ha podido cobrar.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.rechazos',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Rechazos
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Visualizá, creá y administrá los planes de tu negocio.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.planes',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Planes
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Aquí encontrarás planes creados, administrados y gestionados por el
            negocio. Se diferencian por tener un nivel de acceso Local.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.locales',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Planes Locales
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Podrás encontrar planes pertenecientes a Cadena. Estos son administrados
            por ellos y se diferencian por tener un nivel de acceso que permite
            ingresar a varias sedes.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.cadena',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Planes Cadena
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Simplificá la gestión de cargos adicionales en tu sede: visualizá, creá
            y administrá los cobros para lograr un control total de tus finanzas.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.adicionales',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Cobros Adicionales
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Visualizá y configurá la información de tu sede.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.sedes',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Sedes
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Descargá tus reportes aquí. Obtené toda la información que necesitás de manera precisa y completa.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.reportes',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Reportes
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Observá y gestioná tus campañas. Podrás vender planes con descuentos
            exclusivos.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.corporativo',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Campañas
        </Typography>
      ),
    },
    {
      content: (
        <>
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
              Si tu negocio no ha sido inaugurado todavía, creá y promocioná campañas
              preventa . De esta manera, podrás vender planes anticipadamente
              ofreciendo descuentos exclusivos.
          </Typography>
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
              Crea campañas por convenios. Podrás ofrecer promociones exclusivas.
          </Typography>
        </>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.create',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Crear
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Configurá la información fiscal de la sede y las credenciales para poder
            comenzar a vender planes y facturar desde la plataforma.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.merchants',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Negocios
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Creá y administrá los servicios que ofrece SportClub. De esta forma, los usuarios de sedes podrán seleccionar sus servicios a la hora de configurar la misma.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.servicios',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Servicios
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Creá y administrá las actividades que ofrece SportClub. De esta forma, los usuarios de sedes podrán seleccionar sus actividades a la hora de configurar la misma.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.actividades',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Actividades
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Creá y gestioná nuevos puntos de accesos. Aquí podrás configurar los QR de acceso para los molinetes de las sedes.
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.punto_acceso',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Puntos de Accesos
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body1"
          fontWeight={400}
          alignSelf={'flex-start'}
          gutterBottom
        >
            Gestioná los usuarios de tu sede para que puedan navegar por la plataforma! Podrás asignar diferentes roles a tus usuarios, cada uno de ellos poseen permisos diferentes!
        </Typography>
      ),
      isFixed: true,
      placement: 'auto',
      target: '.users',
      title: (
        <Typography
          variant="subtitle1"
          gutterBottom
          fontWeight={500}
          alignSelf={'flex-start'}
        >
            Usuarios
        </Typography>
      ),
    }
  ];
  // Si es PUNTO DE ACCESO , Solo se muestra Puntos de acceso
  if(isAccessPoint){
    const indexToShow = [19];
    const filteredSteps = conditionalSteps.filter((_, index) => indexToShow.includes(index));
    steps.push(...filteredSteps);
  }
  // Si es OPERADOR, se incluye en los steps a continuación :
  if(isOperator){
    const indexToShow = [0,2,3,4,5,6,7,8,9,10,11];
    const filteredSteps = conditionalSteps.filter((_, index) => indexToShow.includes(index));
    steps.push(...filteredSteps);
  }
  // Si es SOLO ACCESOS , se incluye en los steps solo los 'Accesos' y 'Red SC'
  if(isOnlyAccess){
    const indexToShow = [4,5];
    const filteredSteps = conditionalSteps.filter((_, index) => indexToShow.includes(index));
    steps.push(...filteredSteps);
  }
  // Si es SUPERADMIN se mantiene todos los STEPS
  if(isSuperAdmin){
    steps.push(...conditionalSteps);
  }
  // Si es ADMIN se eliminara de los STEPS los indexs mencionados a continuación
  if(isAdmin){
    const indexToRemove = merchant?.negocio_central === false ? [17,18,19] :[9,17,18,19]  ;
    const filteredSteps = conditionalSteps.filter((_, index) => !indexToRemove.includes(index));
    steps.push(...filteredSteps);
  }
  return steps;
}; 