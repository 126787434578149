import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItemLink, useResourceDefinitions, useSidebarState } from 'react-admin';
import MenuContextMerchant from './MenuContextMerchant';
import { Collapse, Grow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box, Divider, Fade } from '@mui/material';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
const MenuCustom = (props) => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  const filteredResources = resources.filter(item => !item?.options?.hidden);
  const sidebarOpen = useSidebarState();
  const handleChange = () => sidebarOpen[1](window.innerWidth > 600);

  useEffect(() => {
    handleChange();
    window.addEventListener('resize', handleChange);
    return () => window.removeEventListener('resize', handleChange);
  }, []);

  return (
    <Menu {...props}>
      <MenuContextMerchant/>
      <Divider style={{margin: '3px 18px 15px 18px'}}/>
      <Box
        id='scrollableDivJoyRide'
        sx={{
          height: '63vh', 
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '12px',
          }
        }}
      >
        {
          filteredResources.map((resource,index) => {
            if(resource.options?.isMenuParent){
              return(
                <>
                  <SectionParent id={index} resource={resource} resources={filteredResources}/>
                </>
              );
            }else{
              if(resource.hasList && !resource.options?.menuParent){
                return(
                  <>
                    <MenuItemLink
                      id='dashboard'
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={(resource.options && resource.options.label) || resource.name}
                      leftIcon={resource.icon ? <resource.icon /> : null}
                      sidebarIsOpen={open}
                      style={{
                        color: grey[600],
                        padding: '6px 16px',
                        margin: sidebarOpen[0] ? '0 12px' : '3px',
                        fontWeight: '600',
                        borderRadius: '12px',
                        fontSize: '0.85rem',
                        lineHeight: '1.75',
                        letterSpacing: '0.01em',
                      }}
                    />
                  </>
                );
              }
            }
        
          })
        }
      </Box>
      <Divider sx={{margin: '0px 18px 15px 18px'}}/>
    </Menu>
  );
};

const SectionParent = ({resource, resources}) => {
  const [open, setOpen] = useState(true);
  const sidebarOpen = useSidebarState();

  return(
    <Box 
      style={{
        marginBottom: open && '6px',
      }}
    >
      <Box style={{
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0px 12px', 
        padding: '9px 9px 9px 15px',
        cursor: 'pointer',
        borderRadius: '12px',
        // '&:hover': {
        //   backgroundColor: grey[200]
        // }
      }}
      onClick={() => setOpen(!open)}
      >
        {
          sidebarOpen[0] && (
            <>
              <Typography 
                variant='subtitle2' 
                style={{
                  color: grey[600],
                  letterSpacing: '0.05em',
                  fontFamily: 'Raleway, sans-serif',
                  fontWeight: '500',
                  fontSize: '0.78rem'
                }}
              >
                {resource.name}
              </Typography>
              {
                open ? <MdExpandLess style={{color: grey[600]}}/> : <MdExpandMore style={{color: grey[600]}}/>
              }
            </>
          )
        }
      </Box>
      <Collapse in={!sidebarOpen[0] || open} unmountOnExit >
        {
          resources.map((item) => {
            if(item.options?.menuParent && item.options?.menuParent === resource.name){
              return(
                <MenuItemLink
                  className={item?.name}
                  key={item.name}
                  to={`/${item.name}`}
                  primaryText={(item.options && item.options.label) || item.name}
                  leftIcon={item.icon ? <item.icon /> : null}
                  sidebarIsOpen={open}
                  style={{
                    margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px', 
                    color: grey[600],
                    padding: '6px 16px',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '0.85rem',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                    '&.active': {
                      backgroundColor: grey[200],
                      color: '#5584AC',
                      '& .MuiListItemIcon-root':{
                        color: '#5584AC',
                      }
                    }
                  }}
                />
              );
            }
          })
        }
      </Collapse>
    </Box>
  );
};

export default MenuCustom;