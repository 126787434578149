import { Box, Typography } from '@mui/material';
import { AiOutlineWarning } from 'react-icons/ai';

const styleWarning = {
  'background-color': '#fff59d',
  'border-radius': '5px',
  'box-shadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  'width': '100%',
  'margin': '10px',
  'padding': '15px',
  'display': 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  'align-items': 'center'
};

const styledType = {
  'margin-left': '10px',
  'font-size': '12px', 
  'font-weight': '300', 
  'width': '100%'
};

export const WarningComponent = ({position, style, children, noIcon, styledTypo}) => {
  return(
    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
      <Box style={{...styleWarning, ...style}}>
        {!noIcon &&   <AiOutlineWarning size={30} />}
        <Typography style={{...styledTypo, ...styledType}}>
          {children}
        </Typography>
      </Box>
    </Box>
  );
};