import React, {useEffect, useState} from 'react';
import { Box, Typography } from '@mui/material';
import { Datagrid, List, TextField, FunctionField, DateField, TopToolbar,
  TextInput, ReferenceInput, AutocompleteInput, CreateButton, useRedirect, useDataProvider, ReferenceField, ReferenceArrayField, ReferenceManyField } from 'react-admin';
import ExportCustomButton from '../../Components/UI/ExportCustomButton';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import {BsPersonCheck, BsPersonX} from 'react-icons/bs';
import {CgPlayListCheck, CgPlayListRemove} from 'react-icons/cg';

import { theme } from '../../Theme/theme';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { getPermissions } from '../helpers/helpFunctions';


const AccessosList = props => {
  const permis = props.permissions;
  const redirect = useRedirect();

  const handleClickCreate = () => {
    redirect('/accesos/create');
  };
  const defaultFilter = [
    <TextInputIcon
      source="socio_documento" 
      label=""
      placeholder='Documento'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      color='default'
      minLetters={3}
      style={{width: '100%'}}
    />,
  ];

  const accessFilter = [
    <TextInputIcon
      source="socio_documento" 
      label=""
      placeholder='Documento'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      color='default'
      minLetters={3}
      style={{width: '100%'}}
    />,
    <ReferenceInput label="Sede" source="sede_id" perPage={50} reference="sedes" variant='outlined'>
      <AutocompleteInput
        variant='outlined'
        label='Merchants'
        size='small'
        resettable
      /> 
    </ReferenceInput>
  ];
  

  const ListActions = () => {
    const permis = getPermissions();
    return(
      <TopToolbar>
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <CreateButton />
          <ExportCustomButton  viewRangeDate/>
        </Box>
      </TopToolbar>
    );
  };
  return (
    <List 
      perPage={25}
      actions={<ListActions/>}
      bulkActionButtons={false} filters={permis !== 'accesos' ? accessFilter : defaultFilter}
      sort={{ field: 'instances.time', order: 'DESC' }} {...props}
      empty={false}
    >
      <DatagridCustom rowClick='show'>
        <TextField source="socio_nombre" label='Nombre'/>
        <TextField source="socio_apellido" label='Apellido'/>
        {/* <Vigencia source label="Vigencia" />
        <AptoMedico source label="Apto Médico" /> */}
        <FunctionField render={(record) => record?.entered ? (<TaskAltIcon style={{color: theme.palette.secondary.main}} />) : (<DoNotDisturbAltIcon style={{color: theme.palette.error.light}} />)} source="entered" label="Acceso" />
        <FunctionField render={(record) => record?.instances?.[record?.instances.length - 1]?.invalid_access_reason} source="instances.invalid_access_reason" label="Motivo" />
        <TextField source="socio_documento" label='Documento'/>
        <DateField source='date' label='Fecha y Hora' showTime/>
        <TextField source="sede_name" label='Sede'/>
      </DatagridCustom>
    </List>
  );
};

export default AccessosList;
