import React, { useContext, useEffect, useState } from 'react';
import { Box, Collapse, Fade, Grow, IconButton, Typography, Zoom , Popover} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { theme } from '../../../Theme/theme';
import { grey } from '@mui/material/colors';
import informacionSocio from '../../../Resources/socios/SectionsEdit/InformacionSocio';
import { BiUser } from 'react-icons/bi';
import FormTab from '../../Form/FormTab';
import ModalCustom from '../ModalCustom';
import { DispatchErrorContext } from '../../../Context/menuContext';
import { useRefresh } from 'react-admin';

const styleCard = {
  width: '100%',
  minWidth: '650px',
  padding: '24px 32px',
  backgroundColor: '#FFF',
  borderRadius: '12px',
  maxWidth: '1200px',
  zIndex: '2',
};

const styleCardHeader = {
  boxShadow: '0 0 2px 0 rgb(0 0 30 / 10%), 0 2px 4px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%)',
  border: 'none',
};

const styleCardBody = {
  border: '1px solid #d4d4e8',
  boxShadow: 'inset 0 0 0 1px #fff, inset 0 -2px 4px 0 #ececf8',
  position: 'relative',
};

const styleColorTitle = {
  color: grey[900],
  fontSize: '16px',
  lineHeight: '1.5',
  fontWeight: theme.typography.fontWeightBold,
  margin: ' 0 0 5px 2px',
  zIndex: '9'
};

const headCardStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: '7px',
};

const collapsedStyle = {
  paddingBottom: '6px',
  borderBottom: '1px solid #d4d4e8',
};

const CardBasic = ({
  route,
  onSuccess,
  popOver,
  defaultOpen, 
  scrollable,
  record, 
  children, 
  title, 
  asideAction, 
  bigCard, 
  styleBox, 
  collapsed,
  editComponent, 
  transform, 
  confirmModal,
  styleCardSx
  
}) => {
  const [collapse, setCollapse] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const refresh = useRefresh();
  const { clearAllDispatchFields } = useContext(DispatchErrorContext);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  
  useEffect(() => {
    if(!open) {
      refresh();
    }
  },[open]);
  const handleClose = () => {
    clearAllDispatchFields();
    setOpen(false);
  };
  useEffect(() => {
    defaultOpen && setOpen(true);
  },[defaultOpen]);
  
  return (
    <>
      <Box 
        style={ styleBox ? styleBox : { marginBottom : '24px'}}
      >
        <Fade
          in={children ? true : false}
          timeout={600}
        >
          {
            title ? (
              <Box>
                <Box style={(collapsed && !collapse) ? {...headCardStyle, ...collapsedStyle} : headCardStyle}>
                  <Box style={{alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                    <Typography style={styleColorTitle}>{title}</Typography>
                    {
                      popOver && (
                        <>
                          <IconButton style={{ margin: ' 0 0 5px 2px'}} aria-label="delete" color='secondary' onClick={handleClickMenu}>
                            <HelpOutlineIcon sx={{fontSize: '21px'}} />
                          </IconButton>
                          <Popover
                            open={openMenu}
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                          >
                            <Box sx={{
                              maxWidth: '320px',
                              backgroundColor: '#fff',
                              padding: '15px 18px'
                            }}>
                              <Typography variant='subtitle2' style={{fontWeight: '400', color: grey[600]}}>{popOver}</Typography>
                            </Box>
                          </Popover>
                        </>
                      )
                    }
                  </Box>
                  {
                    collapsed ? (
                      <>
                        {
                          collapse ? (
                            <IconButton aria-label="span" onClick={()=> setCollapse(false)}>
                              <KeyboardArrowUpOutlinedIcon style={{fontSize: '20px'}}/>
                            </IconButton>
                          ) : (
                            <IconButton aria-label="collapse" onClick={()=> setCollapse(true)}>
                              <KeyboardArrowDownOutlinedIcon style={{fontSize: '20px'}}/>
                            </IconButton>
                          )
                        }
                      </>
                    ) : (
                      <>
                        { asideAction && asideAction }
                      </>
                    )
                  }
                </Box>
                {
                  collapsed ? (
                    <Collapse  in={collapse}>
                      <Box style={bigCard ? {} : {...styleCard, ...styleCardSx, ...styleCardBody}}>
                        {record && 
                <Box 
                  sx={{
                    width: '35px', 
                    height: '35px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute', 
                    top: 10, 
                    right: 10, 
                    cursor: 'pointer',
                    zIndex: 9999,
                    '&:hover': {
                      backgroundColor: grey[200],
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }} 
                  onClick={() => setOpen(true)}
                >
                  <ModeEditIcon 
                    sx={{fontSize: '20px', 
                      color: theme.palette.primary.main, 
                    }}/>
                </Box>
                        }
                        {children}
                      </Box>
                    </Collapse>
                  ) : (
                    <Box style={bigCard ? {} : {...styleCard, ...styleCardSx, ...styleCardBody}} paddingTop={record ? '100px' : ''}>
                      {record && editComponent && 
                   <Box 
                     sx={{
                       width: '35px', 
                       height: '35px',
                       borderRadius: '50%',
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center',
                       position: 'absolute', 
                       top: 10, 
                       right: 10, 
                       cursor: 'pointer',
                       zIndex: 9999,
                       '&:hover': {
                         backgroundColor: grey[200],
                         opacity: [0.9, 0.8, 0.7],
                       },
                     }} 
                     onClick={() => setOpen(true)}
                   >
                     <ModeEditIcon 
                       sx={{fontSize: '20px', 
                         color: theme.palette.primary.main,
                       }}/>
                   </Box>
                      }
                      {children}
                    </Box>
                  )
                }
              </Box>
            ) : (
              <Box style={{width: '100%', display:'flex', marginBottom: '32px'}}>
                <Box style={bigCard ? {width: '100%'} : {...styleCard, ...styleCardSx, ...styleCardHeader}} position={'relative'}>
                  {record && editComponent && 
                <Box 
                  sx={{
                    width: '35px', 
                    height: '35px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute', 
                    top: 10, 
                    right: 10, 
                    cursor: 'pointer',
                    zIndex: 9999,
                    '&:hover': {
                      backgroundColor: grey[200],
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }} 
                  onClick={() => setOpen(true)}
                >
                  <ModeEditIcon 
                    sx={{fontSize: '20px', 
                      color: theme.palette.primary.main, 
                    }}/>
                </Box>
                  }
                  {children}
                </Box>
              </Box>
            )
          }
        </Fade>
      </Box>
      {open && (
        <ModalCustom onClose={handleClose} open={open} setOpen={setOpen} title={editComponent?.label} queryOverflow>
          <FormTab
            transform={transform}
            modalConfirm={confirmModal} 
            scrollable={scrollable} 
            isEdit 
            onSuccess={onSuccess} 
            route={route} 
            open={open}
            setOpen={setOpen} 
            defaultValues={record} 
            currentTab={0} 
            tabs={[editComponent]}
          />
        </ModalCustom>  
      )}
    </>
  );
};

export default CardBasic;
