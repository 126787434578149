import { Avatar, Divider, Typography } from '@mui/material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useLogout, UserMenu } from 'react-admin';
import { FaUser } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { theme } from '../../../Theme/theme';

const UserMenuCustom = ({token, logout,...props}) => {
  const logoutClick = useLogout();

  function clearCache() {
    if (window.caches && window.caches.keys) {
      // Clear all caches
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }
  }

  const handlelogout = () => {
    logoutClick();
    clearCache();
  };

  return (
    <UserMenu {...props}>
      <Box sx={{display: 'flex', flexDirection: 'column',justifyContent: 'space-between', width: '100%', padding: '21px', gap: '21px'}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '18px'}}>
          <Avatar><FaUser/></Avatar>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant='subtitle2'>{token?.email}</Typography>
            <Typography variant='body2' style={{color: theme.palette.grey[600]}}>{token?.role}</Typography>
          </Box>
        </Box>
        <Divider/>
        <Button variant="outlined" style={{display: 'flex', justifyContent: 'space-around'}} onClick={handlelogout} startIcon={<FiLogOut/>}>
          Cerrar Sesion
        </Button>
      </Box>
    </UserMenu>
  );
};

export default UserMenuCustom;