import { makeStyles } from '@mui/styles';
import React from 'react';
import { 
  DeleteWithConfirmButton,
  ResettableTextField,
  SaveButton,
  Toolbar,
  useRedirect, 
  useSaveContext
} from 'react-admin';
import { createNotification, handleClickCopy } from '../../../Resources/helpers/helpFunctions';
import { Box, Button } from '@mui/material';

const useStyles = makeStyles(theme => ({
  buttonCancel: {
    fontSize: theme.typography.fontSize,
    padding: '6px 26px',
    textTransform: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    justifySelf: 'flex-start',
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.grey[600],
    color: '#fff',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
    '& > span > svg': {
      fontSize: '18px !important'
    }
  },
  buttonDelete: {
    fontSize: theme.typography.fontSize,
    padding: '6px 26px',
    textTransform: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    justifySelf: 'flex-start',
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.error.dark,
    color: '#fff',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      color: '#fff'
    },
    '& > span > svg': {
      fontSize: '18px !important'
    }
  },
  buttonSave: {
    fontSize: theme.typography.fontSize,
    padding: '6px 26px',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    justifySelf: 'flex-start',
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff'
    },
    '& > span > svg': {
      fontSize: '18px !important'
    }
  }
}));

export const CustomEditToolbar = ({
  idForm,
  submitOnEnter,
  handleSubmitWithRedirect,
  pristine,
  saving,
  validateSubmit,
  isEdit,
  deleteEnabled,
  source,
  noShow,
  successFun,
  onSuccessCustom,
  alwaysEnable,
  handleValidations,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const permis = localStorage.getItem('permissions')
  const handleCancel = () => {
    if(isEdit){
      noShow ? 
        redirect(`${props.basePath}`) :
        redirect(`${props.basePath}/${props.record?.id}/show`);
    }else{
      redirect(props.basePath);
    }
  };

  
  const onFailureDelete = ({data, msg, error}) => {
    msg ?
      createNotification('error', msg, ()=> handleClickCopy(data)) : 
      createNotification('error', 'Error al eliminar', ()=> handleClickCopy(data));
  };

  const onSuccessDelete = ({data, msg}) => {
    msg ? 
      createNotification('success', msg) : 
      createNotification('success', 'Eliminado correctamente');
    redirect(props.basePath);
  };

  const onSuccess = (rest) => {
    redirect(props.basePath);
    successFun && successFun(rest.data);
    if(isEdit){
      rest.msg ?
        createNotification('success', rest.msg) :
        createNotification('success', 'Editado correctamente');
    }else{
      rest.msg ? 
        createNotification('success', rest.msg) :
        createNotification('success', 'Creado correctamente');
    }
  };

  const onFailureSave = (data,msg) => {
    

    if(isEdit){
      data.msg ? 
        createNotification('error', data.msg, ()=> handleClickCopy(data)) : 
        createNotification('error', 'Error al guardar, por favor intente más tarde', ()=> handleClickCopy(data)); 
    }else{
      data.body.msg ? 
        createNotification('error', data.body.msg, ()=> handleClickCopy(data)) : 
      data.msg ? 
        createNotification('error', data.msg, ()=> handleClickCopy(data)) : 
        createNotification('error', 'Error al crear, por favor intente más tarde', ()=> handleClickCopy(data));
    }
  };

  const handleSubmitCustom = () => {
    const validate = validateSubmit ? validateSubmit() : false;
    console.log('validate', validate);
    if(!validateSubmit){
      handleSubmitWithRedirect();
    }else if(validate){
      handleSubmitWithRedirect();
    }
  };
  
  return (
    <Box style={{
      borderTop: '1px solid #EEEEEE',
      width: '100%'
    }}>
      <Toolbar mutationMode='pessimistic' {...props}>
        {
          props.basePath === "merchants" ? (
          (permis === 'superadmin' || 'owner') && (
          <Box>
            <Button
              className={classes.buttonCancel}
              variant='contained'
              disabled={saving}
              onClick={handleCancel}
            >
            Cancelar
            </Button>
          </Box>
            )) : (
            <Box>
            <Button
              className={classes.buttonCancel}
              variant='contained'
              disabled={saving}
              onClick={handleCancel}
            >
            Cancelar
            </Button>
          </Box>
          )
        }
        {
          deleteEnabled && (
            <Box>
              <DeleteWithConfirmButton
                {...props}
                label='ELIMINAR'
                variant={'contained'}
                className={classes.buttonDelete}
                disabled={saving}
                icon={<></>}
                onFailure={onFailureDelete}
                onSuccess={onSuccessDelete}
                confirmTitle={props.record && (source ? `Eliminar ${props.record[source]}` : props.record?.name ? `Eliminar ${props.record.name}` : 'Eliminar')}
                mutationMode='pessimistic'
                id={props.record?.id}
              />
            </Box>
          )
        }
        <Box>
          <SaveButton
            mutationMode="pessimistic"
            variant='contained' 
            handleSubmitWithRedirect={handleSubmitCustom}
            saving={saving}
            disabled={saving}
            submitOnEnter={submitOnEnter}
            className={classes.buttonSave}
            onSuccess={onSuccessCustom ? onSuccessCustom : onSuccess}
            onFailure={onFailureSave}
            onClick={onClick}
            form={idForm}
          />
        </Box>
      </Toolbar>
      
    </Box>
  );
};
