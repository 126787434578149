import { useDataProvider, useRecordContext } from 'react-admin';
import { BsCardText } from 'react-icons/bs';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { iconsChips } from '../../../Theme/useStyle';
import HeaderCardShow from '../../../Components/UI/Show/HeaderCardShow';
import informacionMerchant from '../Edit/InformacionMerchant';
import { useState } from 'react';
import { useQuery } from 'react-query';

const MerchantHeader = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [sede, setSede] = useState(null);
  const [central, setCentral] = useState(record?.negocio_central ? record?.negocio_central : false);

  const editComponent = {
    component: informacionMerchant(central, setCentral),
    label: 'Información',
    icon: <BsCardText/>
  };
  
  useQuery(
    ['sede'],
    async () => {
      if(record?.sede_principal) {
        const response = await dataProvider.getOne('sedes', {id: record?.sede_principal});
        return await response.data;
      }
    }, {
      onSuccess: (data) => setSede(data),
      onError: (err) => console.log('error', err)
    }
  );


  const transform = (data) => {
    let newData = data;
    if(data?.negocio_central || newData?.sede_principal === '') {
      delete newData.sede_principal;
      return newData;
    }
    return data;
  };

  const chips = [
    {label: record.afip?.email, icon: <MailOutlineOutlinedIcon style={iconsChips}/>},
    {label: record.afip?.telefono, icon: <CorporateFareOutlinedIcon style={iconsChips}/>},
    {label: sede?.name, icon: <StoreMallDirectoryIcon style={iconsChips}/>}
  ];

  return (
    <CardBasic route={'merchants'} editComponent={editComponent} transform={transform} record={record}>
      <HeaderCardShow title={record.name} subtitle={record.slug} chips={chips} archived={record.archivado}/>
    </CardBasic>
  );
};

export default MerchantHeader;