import { Avatar, Box, Chip, Typography } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import React from 'react';
import ImageComponent from './ImageComponent';
import { styleChip } from '../../../Theme/useStyle';

const HeaderCardShow = ({urlImage, archived, title, subtitle, showImage, chips, status}) => {
  return (
    <Box style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <Box style={{display: 'flex', gap: '22px', alignItems: 'center'}}>
        {showImage && (
          urlImage ? (
            <ImageComponent item={{url: urlImage}} hiddenUrl small/>
          ) : (
            <Avatar
              style={{width: '75px', height: '75px'}} 
            >
              <CameraAltOutlinedIcon style={{color: '#3d3d3d'}}/>
            </Avatar>
          )
        )}
        <Box style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <Box style={{display: 'flex', gap: '50px', alignItems: 'center'}}>
            <Typography color='primary' variant='h6'>{title}</Typography>
            {status && status}
            {
              archived && (
                <Chip 
                  size='small' 
                  color='error' 
                  variant='outlined'
                  label='Archivado'
                />
              )
            }
          </Box>
          <Typography variant='subtitle2'>{subtitle}</Typography>
        </Box>
      </Box>
      <Box style={{display: 'flex', gap: '12px', marginTop: '10px', flexWrap: 'wrap'}}>
        {
          chips && chips.map((item) => {
            if(item){
              return(
                <Chip 
                  key={item.key ? item.key : Math.random()}
                  size='small'  
                  variant='outlined' 
                  icon={item.icon}
                  label={item.label}
                  style={styleChip}
                />
              );
            }
          })
        }
      </Box>
    </Box>
  );
};

export default HeaderCardShow;