import { createContext, useState } from 'react';
import { createNotification } from '../../helpers/helpFunctions';


const CorpContext = createContext({});

const CorpProvider = (props) => {
  const [plan, setPlan] = useState('');
  const [boolean, setBoolean] = useState(null);
  const [back, setBack] = useState(true);
  const [contextMerchant, setContextMerchant] = useState(null);

  const corpContext = {
    newPlan: plan,
    boolean: boolean,
    back: back,
    contextMerchant: contextMerchant,
    newBool: () => {
      return true;
    },
    getValues: (value) => {
      return value;
    },
    setBoolean: (bool) => {
      setBoolean(bool);
    },
    setNewPlan: (value) => {
      setPlan(value);
    },
    getBack: (bool) => {
      return setBack(bool);
    },
    setNot: (msg) => {
      createNotification('warning', msg);
    },
    setContext: (merch) => setContextMerchant(merch),

  };

  return( 
    <CorpContext.Provider value={corpContext}>
      {props.children}
    </CorpContext.Provider>
  );

};

export {CorpContext, CorpProvider};
