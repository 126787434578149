import { Box, IconButton, Popover, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';



export const PopoverComponent = ({text}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const openMenu = Boolean(anchorEl);
  
  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton aria-label="delete" color='secondary' onClick={handleClickMenu}>
        <HelpOutlineIcon sx={{fontSize: '21px'}} />
      </IconButton>
      <Popover
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box sx={{
          maxWidth: '320px',
          backgroundColor: '#fff',
          padding: '15px 18px'
        }}>
          <Typography variant='subtitle2' style={{fontWeight: '400', color: grey[600]}}>{text}</Typography>
        </Box>
      </Popover>
    </>
  );
};