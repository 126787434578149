import StoreIcon from '@mui/icons-material/Store';
import { Box } from '@mui/material';
import { getPermissions } from '../helpers/helpFunctions';
import BrandsCreate from './BrandsCreate';
import BrandsEdit from './BrandsEdit';
import BrandsList from './BrandsList';
import BrandsShow from './BrandsShow';

const permis = getPermissions();

export default{
  icon: StoreIcon,
  list: BrandsList,
  create: BrandsCreate,
  show: BrandsShow,
  options: (permis == 'superadmin') ? 
    {label: 'Brands', 'menuParent': 'ADMINISTRATIVO'} :
    {label: 'Mi Brand', 'menuParent': 'ADMINISTRATIVO'}
};