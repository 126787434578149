import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../DataProvider';
import { useQuery } from 'react-query';
import { DispatchErrorContext } from '../Context/menuContext';
import { useForm } from 'react-hook-form';



const useCheckField = (basePath, field, value, newFilter) => {
  const { setIsDisabledTab, getIsDisabled, setIsLoading, getDispatchField, clearDispatchField, errors, setDisableSaveButton } = useContext(DispatchErrorContext);

  let token = localStorage.getItem('auth');
  let tokenSend = token?.slice(10, -2);
  const filter = {[field]: encodeURIComponent(value), ...newFilter};
  const {isLoading, isFetching, refetch} = useQuery([basePath, field],
    () =>  axios({
      url: `${apiUrl}/${basePath}?filter=${JSON.stringify(filter)}&range=[0,5]&sort=["id","ASC"]`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': '',
        'access-control-allow-origin': '*'
      },
    }),
    {
      enabled: false,
      onSuccess: ({data}) => {
        setDisableSaveButton(false);
        if(data?.data?.length > 0){
          // setIsDisabledTab(true);
          getDispatchField(field);
          setIsLoading(false);
          return true;
        }else{
          // setIsDisabledTab(false);
          clearDispatchField(field);
          setIsLoading(false);
          return false;
        }

      },
      onError: (err) => {
        setDisableSaveButton(false);
        throw new Error(err);
      }
      
    }
  );
  if(isLoading || isFetching) {
    setIsLoading(true);
  }

  return refetch;

};


export default useCheckField;
