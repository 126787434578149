import React from 'react';
import Box from '@mui/material/Box';
import { SelectInput } from 'react-admin';
import { useRecordContext } from 'react-admin';
import { styled } from '@mui/system';

const SelectSmall = styled('select')({
  p:{display: 'none'}
});

const CustomSelect = ({choices, source, disabled, onChange, validate, label, optionText, optionValue}) => {
  const record = useRecordContext();
  return (
    <Box>
      <SelectSmall fullWidth source={source} variant='outlined' onChange={onChange}
        initialValue={record ? record[source] : ''} choices={choices}  label={label} optionText={optionText}
        optionValue={optionValue}
        disabled={disabled ? true : false} validate={validate}
      />
    </Box>
  );
};

export default CustomSelect;
