import { useState, useEffect, useRef } from 'react';
import ModalCustom from '../../../Components/UI/ModalCustom';
import {Autocomplete, Box, Paper, Skeleton, TextField, Typography, useMediaQuery} from '@mui/material';
import { FormControl, InputAdornment, InputLabel ,OutlinedInput, Input } from '@mui/material';
import { useDataProvider, useRefresh, NumberInput } from 'react-admin';
import { BiTime } from 'react-icons/bi';
import {MdOutlineAttachMoney } from 'react-icons/md';
import { createNotification } from '../../helpers/helpFunctions';
import axios from 'axios';
import { apiUrl } from '../../../DataProvider';
import { makeStyles } from '@mui/styles';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(1),
      width: 200,
      position: 'relative',
    },
  },
}));

export const ModalDiscount = ({openModal, setOpenModal, record, numeroDeActivos}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [oldPrice, setOldPrice] = useState(record?.price || '');
  const [newPrice, setNewPrice] = useState('');
  const [months, setMonths] = useState(0);
  const [numberMonths, setNumberMonths] = useState('');
  const [merchs, setMerchs] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [backupSociosActivos, setBackupSociosActivos] = useState(null);
  const classes = useStyles();
  const refresh = useRefresh();
  const [validateMonth, setValidateMonth] = useState(null);
  const[validatePrice, setValidatePrice] = useState(null);
  const ref = useRef(true);
  const dataProvider = useDataProvider();

  let params = {filter: {
    'active_plan_id': record?.id,
    status: ['activo']
  }, pagination:{page: 1, perPage: 9999}, sort:{field:'id', order:'ASC'}};

  // CAmbiar esta lógica, deberia venir en un getOne del plan.
  const { data, refetch } = useQuery(['socios_activos'], () => dataProvider.getList('socios', params), {
    onSuccess: (data) => setBackupSociosActivos(data.data.length)
  });
  const { data: {data: merchants} = {} } = useQuery(['merchants'], () => dataProvider.getList('merchants', {filter: {}, pagination:{page: 1, perPage: 9999}, sort:{field:'id', order:'ASC'}}));

  const handleSubmit = async () => {
    const objectData = {
      'aplicaciones_restantes': Number(months),
      'precio_nuevo': Number(newPrice),
      'precio_viejo': Number(oldPrice),
      'plan_id': record?.id,
      'merchants': merchs
    };
    console.log(objectData);
    if(objectData) {
      setProcessing(true);
      const token = localStorage.getItem('auth');
      const tokenSend = await JSON.parse(token)?.token;
      axios({
        method: 'PUT',
        url: `${apiUrl}/descuento_masivo`,
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Tokens': tokenSend,
          'access-control-allow-origin': '*'
        }, 
        data: JSON.stringify(objectData)
      }).then(res => {
        setOpenConfirmModal(false);
        setOpenModal(false);
        refresh();
        createNotification('success', `El ${res.data.msg}`);
        setProcessing(false);

      }).catch(e => {
        
        setOpenConfirmModal(false);
        setProcessing(false);
        createNotification('error', 'Hubo un error, intente mas tarde');
      });
    }
  };

  const handleValidations = (e) => {
    Number(newPrice) <= oldPrice ? setValidatePrice(false) : setValidatePrice(true);
    months <= 0 ? setValidateMonth(false) : setValidateMonth(true);
  };

  // Validations

  useEffect(() => {
    if(!ref.current) 
      months > 0 && setValidateMonth(true),
      (months <= 0 ?? months === '') && setValidateMonth(false);

  }, [months]);

  useEffect(() => {
    if(ref.current) {
      ref.current = false;
      return;
    } else {
      (Number(newPrice) > oldPrice) && setValidatePrice(true),
      (Number(newPrice) <= oldPrice) && setValidatePrice(false);
    }
  }, [newPrice]);


  const cantidadDeMeses = [
    {name: 'Mensual', number: 1},
    {name: 'Trimestral', number: 3},
    {name: 'Cuatrimestral', number: 4},
    {name: 'Semestral', number: 6},
    {name: 'Anual', number: 12}
  ];
  useEffect(() => {
    cantidadDeMeses.map(per => {
      if(per.name === record?.cobro) {
        setNumberMonths(per.number);
      }
    });
  },[newPrice, months]);
 

  //aplicaciones_restantes == meses
  //precio nuevo no puede ser mas bajo que el actual
  // meses no pueden ser 0
  //precio anterior de edit plan precio comparativo
  //"Precios que pagaran los socios activos a la fecha"
  // "Los socios activos a la fecha mantendran este precio"

  const media500 = useMediaQuery('(min-height: 800px)');
  const media1100 = useMediaQuery('(min-width: 1100px)');

  return(
    <>
      {
        openModal && (
          <>
            <ModalCustom
              handleValidations={handleValidations}
              open={openModal} 
              setOpen={setOpenModal} 
              onClose={() => setOpenModal(false)}
              onClickAction={() => setOpenConfirmModal(true)}
              showButtons
              textAction={'Guardar'}
              title="Aumento de Precio Segmentado"
              disabledAction={validatePrice && validateMonth ? false : true}
              queryOverflow={true}
            >
              <Box ref={ref} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px'}}>
                <Typography variant="h7" sx={{fontSize: '0.8em'}}>Al aplicar un aumento de precio segmentado se actualiza el precio del plan y se aplica un descuento a todos los socios activos al día de la fecha para que mantengan el precio anterior por una cantidad de tiempo determinada.</Typography>
                <FormControl sx={{ gap: '2px'}}fullWidth disabled className={classes.root}>
                  <InputLabel htmlFor="outlined-adornment-amount">Los socios activos a la fecha mantendran este precio</InputLabel>
                  <Input
                    disableUnderline
                    fullWidth
                    value={`${record?.price}`}
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="center"><MdOutlineAttachMoney /></InputAdornment>}
                    labelWidth={150}
                  />
                </FormControl>
                <FormControl style={{width: '300px'}}className={classes.root} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Precio Nuevo</InputLabel>
                  <OutlinedInput
                    error={validatePrice === false}
                    type="number"
                    label="Precio Nuevo"
                    style={{width: '100%'}}
                    onChange={(e) => setNewPrice(e.target.value)}
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start"><MdOutlineAttachMoney /></InputAdornment>}


                    labelWidth={100}
                  />
                  {validatePrice === false && <Typography variant="h7" sx={{fontSize: '0.7em', marginTop: '10px', color: '#e57373'}}>El precio nuevo del plan no puede ser inferior al precio actual</Typography>}
                </FormControl>
                <FormControl style={{width: '300px'}} className={classes.root} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Cantidad de períodos</InputLabel>
          
                  <OutlinedInput
                    ref={ref}
                    error={validateMonth === false}
                    type="number"
                    label="Cantidad de períodos"
                    style={{minWidth: '300px'}}
                    onChange={(e) => setMonths(e.target.value)}
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start"><BiTime /></InputAdornment>}
                    endAdornment={validateMonth === true && <Typography style={{fontSize: '0.7em', width: '70px', color: '#22577E'}}>{`${numberMonths * months} meses`}</Typography>}
                    labelWidth={160}
                  />
                  {validateMonth === false && <Typography variant="h7" sx={{fontSize: '0.7em', marginTop: '10px', color: '#e57373'}}>La cantidad de meses no puede ser menor o igual que 0</Typography>}
                </FormControl>
                <InputLabel htmlFor="outlined-adornment-amount">Negocios a excluir</InputLabel>
                <InputLabel sx={{fontSize: '.7em', overflow: 'ellipsis', whiteSpace: 'nowrap'}}>A los socios de negocios excluídos no se les aplicará el descuento de aumento segmentado. El precio del plan para éstos socios será el precio actualizado.</InputLabel>
                {
                  merchants?.length > 0 ? (
                    <Autocomplete 
                      multiple 
                      PaperComponent={(params) => <Paper sx={{marginBottom: '10px'}} {...params} />}
                      onChange={(e,v) => setMerchs(v?.map(item => item.id))}
                      sx={{
                        width: '90%',
                        margin: 0}}
                      getOptionLabel={(option) => option.name} 
                      options={merchants}
                      renderInput={(params) => <TextField variant="outlined" label="Negocios" {...params} />}
                   
                    />
                  ) : (
                    <Skeleton height="50px" width="90%" />
                  )
                }
                {validateMonth && validatePrice && (
                  <>
                    <Typography variant="h6" disabled sx={{fontSize: '1em', marginTop: '10px', color: '#64648C'}}>
                      {`Se aplicara un descuento de $ ${newPrice - oldPrice} a ${backupSociosActivos} socios por ${months} ${months === 1 ? 'período' : 'períodos'} (${numberMonths * months} meses)`}
                    </Typography>
                  </>
                )}
              </Box>
            </ModalCustom>
          </>
        )}
      {openConfirmModal && (
        <>
          <ModalCustom
            open={openConfirmModal}
            setOpen={setOpenConfirmModal}
            onClose={() => setOpenConfirmModal(false)}
            onClickAction={() => handleSubmit()}
            title={'Importante'}
            showButtons
            textAction={'Confirmar'}
            disabledAction={processing}
          >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', '& > :not(style)': { m: 2 }}}>
              <Typography variant="h7">Si confirma, los descuentos insertados no podran ser revertidos de forma masiva.</Typography>
            </Box>
          </ModalCustom>
        </>
      )}
    </>
  );
};