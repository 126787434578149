
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';


const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
});

const aplicationFee = (central) => {
  const record = useRecordContext();
  const[mensual, setMensual] = useState(record?.application_fee?.mensual ? record.application_fee.mensual : 0);
  const[trimestral, setTrimestral] = useState(record?.application_fee?.trimestral ? record.application_fee.trimestral.toString() : 0);
  const[cuatrimestral, setCuatrimestral] = useState(record?.application_fee?.cuatrimestral ? record.application_fee.cuatrimestral : 0);
  const[semestral, setSemestral] = useState(record?.application_fee?.semestral ? record.application_fee.semestral : 0);
  const[anual, setAnual] = useState(record?.application_fee?.anual ? record.application_fee.anual : 0);

  const className = useStyles();

  useEffect(() => {
    if(!record) {
      setMensual(0);
      setTrimestral(0);
      setCuatrimestral(0);
      setSemestral(0);
      setAnual(0);
    }
  },[central]);

  const data =  [
    {
      source: 'application_fee.mensual',
      type: 'number',
      value: mensual,
      onChange: (e) => setMensual(e.target.value),
      className: className.input,
      toNumber: true,
      disabled: central,
      erase: central,
      label: 'Comisión para planes mensuales',
      xs: 6
    },
    {
      source: 'application_fee.trimestral',
      type: 'number',
      value: trimestral,
      onChange: (e) => setTrimestral(e.target.value),
      className: className.input,
      toNumber: true,
      erase: central,
      disabled: central,
      label: 'Comisión para planes trimestrales',
      xs: 6
    },

    {
      source: 'application_fee.cuatrimestral',
      type: 'number',
      value: cuatrimestral,
      onChange: (e) => setCuatrimestral(e.target.value),
      className: className.input,
      toNumber: true,
      erase: central,
      disabled: central,
      label: 'Comisión para planes cuatrimestrales',
      xs: 6
    },
    {
      source: 'application_fee.semestral',
      type: 'number',
      value: semestral,
      onChange: (e) => setSemestral(e.target.value),
      label: 'Comisión para planes semestrales',
      xs: 6,
      className: className.input,
      toNumber: true,
      erase: central,
      disabled: central,
    },
    {
      source: 'application_fee.anual',
      type: 'number',
      value: anual,
      onChange: (e) => setAnual(e.target.value),
      label: 'Comisión para planes anuales',
      className: className.input,
      toNumber: true,
      erase: central,
      disabled: central,
      xs: 6
    }
  ];

  return data;
};

export default aplicationFee;