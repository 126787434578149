import { Box, Typography } from '@mui/material';
import { useDataProvider, useRecordContext, useRedirect, useRefresh } from 'react-admin';
import { BsCreditCard2Back } from 'react-icons/bs';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { credentialsEdit } from '../Edit/CredentialsEdit';
import Habilitado from './../Edit/PlanesCadena';
import { useContext, useEffect, useState } from 'react';
import { apiUrl, merchantContext } from '../../../DataProvider';
import axios from 'axios';
import { createNotification } from '../../helpers/helpFunctions';


const CredentialsCard = () => {
  const record = useRecordContext();
  const { setMerchant, getMerchant } = useContext(merchantContext);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const editComponent = record.credentials && {
    component: credentialsEdit(record),
    label: 'Credenciales',
    icon: <BsCreditCard2Back/>
  };
  const [codeMp, setCodeMp] = useState(null);
  const urlCode = window.location.href.split('?')[1];
  const merch = localStorage.getItem('merchant');
  
  useEffect(() => {
    if(urlCode && urlCode.includes('code=')){
      console.log('entro en useeffect');
      if(merch){
        const code = urlCode.split('=')[1].split('&')[0];
        setCodeMp(code);
        let merchSplit = merch?.split('-.-');
        let newMerch = {id: merchSplit[0], name: merchSplit[1]};
        setMerchant(newMerch);
        setDefaultOpen(true);
        
      }
      setDefaultOpen(true);
    } else {
      setDefaultOpen(false);
      refresh();
    }
    
  },[]);

  const putMarketplace = async (code) => {
    let auth = localStorage.getItem('auth'); 
    auth = await JSON.parse(auth);
    const url = `${apiUrl}/marketplace/${getMerchant()?.id}`;
    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Expose-Headers': 'content-range',
      'Context-Merchant': getMerchant()?.id,
      'X-Access-Tokens': auth?.token
    };
    return axios({
      method: 'PUT',
      url: url,
      headers: headers,
      data: {
        'marketplace_code': code
      }
    }).then(data => {
      createNotification('success', 'Las credenciales de marketplace se guardaron con éxito.');
      window.location.reload();
    })
      .catch(err => {
        createNotification('error', 'Hubo un error al guardar las credenciales de mercadopago, por favor volver a intentar.');
      });
  };
  

  const postSave = () => {
    if(codeMp.length > 0) {
      localStorage.removeItem('merchant');

      return putMarketplace(codeMp);
    }
    return;
  };

  return (
    <CardBasic route={'merchants'} defaultOpen={defaultOpen} editComponent={editComponent} record={record} onSuccess={postSave} title='Procesadores de pago'>
      {record.credentials && (
        <>
          <ActivadoTag boolean={record?.credentials?.mp_marketplace?.active} title="Mercado Pago" />
          <ActivadoTag boolean={record.credentials?.decidir?.active} title="Decidir" />
        </>
      )}
    </CardBasic>
  );
};

export default CredentialsCard;


export const ActivadoTag = ({boolean, title}) => (
  <Box
    style={{
      color: `${boolean ? '#22577E' : '#cccab5' }`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: 'normal',
      backgroundColor:'#FFFFFF' }}
  >
    <Typography variant="h7" style={{ margin: '10px', color: 'black'}}>{title}</Typography>
    {boolean && <Box sx={{ borderRadius: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', margin: '0 100px 0 20px'}}>
      <Typography style={{fontSize: '0.7em',textTransform: 'uppercase', color: '#90cc00', fontWeight: '600', border: '1px solid #90cc00', borderRadius: '15px', padding: '2px 5px'}}>Activado</Typography>
    </Box>}
    {!boolean && (
      <Typography style={{fontSize: '0.7em',textTransform: 'uppercase', padding: '2px 5px', color: '#ff867c',border: '1px solid #ff867c', borderRadius: '15px', fontWeight: '400', margin: '0 100px 0 20px'}}>Desactivado</Typography>
    )
    }
    {/* <Habilitado.Habilitado boolean={boolean} /> */}
  </Box>
);