import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { getToken } from '../Resources/helpers/helpFunctions';
import { createContext } from 'react';

export let apiUrl = process.env.REACT_APP_APIURL;

export let merchantSelected = {id: '', name: ''};

export let merchantsInToken = [{id: '', name: ''}];

export let merchantCentral = [];

export let isMerchantHabilitado = false;

export let brandSportClub = getToken()?.brand_id === '6328afb359d052e6817c2200';

export const merchantCadena = '6178652dcfb117ad58a2cd3d';
export const merchantContext = createContext({ 
  setMerchant: (merch, ...rest) => {
    merchantSelected = merch;
  },
  getMerchant: () => {
    return merchantSelected;
  },
  setAllMerchants: (data) => {
    merchantsInToken = data;
  },
  setMerchantCentral: (data, boolean) => {
    let notFound = merchantCentral.find(item => item.id === data);
    if(!notFound) {
      merchantCentral.push({id: data, central: boolean});
    }
  },
  setMerchantHabilitado: (boolean) => {
    isMerchantHabilitado = boolean;
  },
  contextMerchant: merchantSelected
});

const httpClient = async (url, options = {}, noContext) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Access-Control-Allow-Origin', '*');
  options.headers.set('Access-Control-Expose-Headers', 'content-range');
  !noContext ? 
    options.headers.set('Context-Merchant', (!merchantSelected || !merchantSelected.id || merchantSelected.id == '0') ? '' : merchantSelected.id) :
    options.headers.set('Context-Merchant', '');
  let auth = localStorage.getItem('auth'); 
  auth = await JSON.parse(auth);
  options.headers.set('X-Access-Tokens', auth?.token);
  const fetchJsonUrl = fetchUtils.fetchJson(url, options);
  return fetchJsonUrl;
};

export default {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    let url;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    resource == 'centrales' ? 
      url = `${apiUrl}/planes?${stringify(query)}` :
      resource == 'multinegocio' ? 
        url = `${apiUrl}/planes?${stringify(query)}` :
        url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, {}, params.noContext).then(({ headers, json }) => ({
      data: json['data'],
      total: parseInt(headers.get('content-range')?.split('/').pop(), 10),
    })
    );
  },

  getOne: (resource, params) =>{
    const res = resource === 'centrales' || resource === 'multinegocio' ? 'planes' : resource;
    return httpClient(`${apiUrl}/${res}/${params.id}`).then(({ json }) => {
      
      return ({
        data: json?.['data'],
      });
    }).catch(err => {
      console.log('GET ONE ERROR', err);
    });},

  getFile: (resource) =>
    httpClient(`${apiUrl}/${resource}`).then((item) => ({
      data: item,
    })),
  
  getOneFilter: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json['data'],
    }));
  },

  getMany: (resource, params) => {
    let noContext = false;
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
      sort: JSON.stringify(['id', 'ASC']),
      range: JSON.stringify([1,9999])
    };
    
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, {}, noContext).then(({ json }) => ({ data: json['data'] }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range')?.split('/').pop(), 10),
    }));
  },

  update: (resource, params) => {
    const res = resource === 'centrales' || resource === 'multinegocio' ? 'planes' : resource;
    
    const blacklist_keys = ['id,', '_archived', '_draft', 'plan-color', 'socios', 'precio-empleado', 'precio-familiar', 'tiene-planes-familiares-2', 'precio-plan-nativo', 'porcentaje-cupon'];
    var filtered_blackkeys_request = params.data;
    blacklist_keys.map(key=> delete filtered_blackkeys_request[key]);
    return new Promise((resolve, reject) => {
      httpClient(`${apiUrl}/${res}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(filtered_blackkeys_request),
      }, params.noContext).then((data) => {
        return(
          data.json.data ? 
            resolve({ 
              data: data.json.data && data.json.data,
              msg: data.json.msg
            }) : (
              reject({msg: data.json.msg})
            )
        );
      }).catch((e) => {
        return reject(e);
      });
    });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    const res = resource === 'centrales' || resource === 'multinegocio' ? 'planes' : resource;
    
    return(
      httpClient(`${apiUrl}/${res}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        return(
          {
            data: { ...params.data, id: json.data.id },
            msg: json.msg
          }
        );
      }).catch(e => {
        return Error(e);
      })
    );
  },

  delete: (resource, params) => {
    return new Promise((resolve, reject) => {
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'DELETE',
        body: params.data ? JSON.stringify(params.data) : null
      }).then(({json}) => {
        return resolve({ data: json});
      }).catch(e => {
        return reject(e.body);
      });
    });
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json['data'] }));
  },
};