import { Avatar, Box, Button, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MdKeyboardArrowRight } from 'react-icons/md';
import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { apiUrl, merchantContext } from '../../../../DataProvider';
import { Collapse, Fade, Skeleton } from '@mui/material';
import { TextInputSimple } from '../../../TextInputIcon/TextInputIcon';
import { useRefresh, useSidebarState } from 'react-admin';
import { getPermissions, getToken } from '../../../../Resources/helpers/helpFunctions';
import { useDebounce } from '../../../../Hooks/useDebounce';
import { useOnClickOutside } from '../../../../Hooks/useOnClickOutside';
import { theme } from '../../../../Theme/theme';
import { useQuery } from 'react-query';
import { DispatchErrorContext } from '../../../../Context/menuContext';
import { CorpContext } from '../../../../Resources/socios/SectionsEdit/ContextCorp';

const MenuContextMerchant = () => {
  const [open, setOpen] = useState(false);
  const [merchants, setMerchants] = useState(null);
  const sidebarOpen = useSidebarState();
  const ref = useRef();
  const url = window.location.href.split('?')[1];
  const permis= getPermissions();
  const rolAccesos = permis === 'accesos';
  const rolMolinetes = permis === 'molinetes';
  useOnClickOutside(ref, () => setOpen(false));
  const { setMerchant, getMerchant, setAllMerchants, setMerchantCentral, setMerchantHabilitado } = useContext(merchantContext);
  const { setContext } = useContext(CorpContext);
  const refresh = useRefresh();
  let token = localStorage.getItem('auth');
  let tokenSend = token?.slice(10, -2);
  let tokenDecode = getToken();
  const merchantSelect = localStorage.getItem('merchant');
  useEffect(() => {
    if(Boolean(!merchants) && getToken()?.role !== 'molinetes') refetch();
  },[]);
  
  const { refetch } = useQuery(
    ['merchantsContext'],
    () => axios({
      url: `${apiUrl}/merchants?filter={}&range=[0,999]&sort=["id","ASC"]`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': '',
        'access-control-allow-origin': '*'
      },
    }),
    {
      enabled: Boolean(!merchants) && getToken()?.role !== 'molinetes',
      onSuccess: ({data}) => {
        if (url && url.includes('code=')) {
          if(tokenDecode.role === 'owner' || tokenDecode.role === 'superadmin') {
            setMerchants([{name: 'Ver Todos', id: ''}, ...data.data]);
          } else {
            setMerchants(data.data);
          }
          const merch = localStorage.getItem('merchant');
          let merchSplit = merch?.split('-.-');
          let newMerch = {id: merchSplit[0], name: merchSplit[1]};
          setMerchant(newMerch);
          setContext(newMerch);
          return;
        } else if(tokenDecode.role === 'owner' || tokenDecode.role === 'superadmin'){
          setMerchants([{name: 'Ver Todos', id: ''}, ...data.data]);
          data?.data?.map(item => item?.negocio_central && setMerchantCentral(item?.id, true));
          setMerchant({name: 'Ver Todos', id: ''});
          setContext({name: 'Ver Todos', id: ''});

          setAllMerchants([{name: 'Ver Todos', id: ''}, ...data.data]);
        }  else {
          data?.data?.map(item => item?.negocio_central && setMerchantCentral(item?.id, true));
          setAllMerchants(data.data);
          setMerchants(data.data);
          setMerchant(data.data[0]);
          setContext(data.data[0]);

        }
        refresh({hard: true});
      },
    }
  );
  return (
    <Box sx={{position: 'sticky'}} ref={ref}>
      <Tooltip 
        title={
          <Box>
            <Typography variant='subtitle2' style={{fontSize: '12px'}}>
              {getMerchant()?.name !== '' ? getMerchant()?.name : ''}
            </Typography>
            <Typography variant='subtitle2' style={{fontSize: '12px'}}>
              {tokenDecode?.email}
            </Typography>
          </Box>
        } 
        placement="bottom-start"
      >
        <Box
          id='selectMerchant' 
          sx={{
            display: 'flex',
            gap: '9px', 
            margin: sidebarOpen[0] ? '0 12px 12px 12px' : '0 3px 12px 0px',
            marginBottom: '12px',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '10px',
            padding: sidebarOpen[0] ? '10px' : '6px',
            backgroundColor: open ? 'rgba(200,200,200, .3)' : 'transparent',
            '&:hover':{
              backgroundColor: 'rgba(200,200,200, .3)'
            }
          }}
          onClick={() => {
            if(!rolAccesos && !rolMolinetes){
              setOpen(!open && true);
            }
          }}
        >
          <Avatar variant="rounded" src={'https://muvimages.s3.us-east-2.amazonaws.com/1.png'}/>
          <Box sx={{display: 'flex', flexDirection: 'column',  overflow: 'hidden'}}>
            {/* <Typography
              variant='subtitle2' 
              style={{fontSize:'12px', color: grey[600]}}
            >Negocio</Typography> */}
            {
              getMerchant()?.name !== '' && !rolAccesos && !rolMolinetes ? (
                <Box 
                  sx={{
                    display: 'flex',
                    alignItems:  getMerchant()?.name?.length > 18 ? 'start' : 'center',
                    overflow: 'hidden',
                    width: '100px',
                    height: '44px'
                  }}>
                  <Typography 
                    variant='subtitle2' 
                    // style={{whiteSpace: 'nowrap', overflow: 'hidden', width: '115px'}}
                  >
                    {/* {getMerchant()?.name} */}
                    {getMerchant()?.name?.length > 18 ? getMerchant()?.name.slice(0,17) + '...' : getMerchant()?.name ?? ''}
                  </Typography>
                </Box>
              ) : (rolMolinetes || rolAccesos) ? (
                <Box 
                  sx={{
                    display: 'flex',
                    alignItems:  getMerchant()?.name?.length > 18 ? 'start' : 'center',
                    overflow: 'hidden',
                    width: '100px',
                    height: '44px'
                  }}>
                  <Typography 
                    variant='subtitle2' 
                  // style={{whiteSpace: 'nowrap', overflow: 'hidden', width: '115px'}}
                  >
                    {rolMolinetes && 'Molinetes'}
                    {rolAccesos && 'Accesos'}
                  </Typography>
                </Box>
              ) :
                (
                  <Box sx={{
                    width: '110px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Skeleton/>
                  </Box>
                )
            }
            {/* <Typography variant='subtitle2' style={{fontSize:'12px', color: grey[600]}}>
              {tokenDecode?.email?.length > 17 ? tokenDecode?.email.slice(0, 14) + '...' : tokenDecode?.email ?? ''}
            </Typography> */}
          </Box>
          {
            sidebarOpen[0] && (!rolMolinetes && !rolAccesos) && (
              <Box style={{width: '18px', height: '18px'}}>
                <MdKeyboardArrowRight style={{width: '18px', height: '18px'}}/>
              </Box>
            )
          }
        </Box>
      </Tooltip>
      <BoxListMerchants list={merchants} setOpen={setOpen} open={open}/>
      
        
    </Box>
  );
};

const BoxListMerchants = ({list, setOpen, open}) => {
  const [merchantsFilter, setMerchantsFilter] = useState(list);
  
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 500);  

  const sidebarOpen = useSidebarState();;
  const [maxMerchants, setMaxMerchants] = useState(25);
  const { setMerchant, getMerchant, setMerchantHabilitado } = useContext(merchantContext);
  const { setContext } = useContext(CorpContext);
  const {setUpdatedMerchant} = useContext(DispatchErrorContext);
  const refresh = useRefresh({hard: true});

  useEffect(() => {
    if(list){
      let updatedMerchants = [...list];
      if(search){
        updatedMerchants = updatedMerchants.filter(m => 
          m.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        );
      }
      setMerchantsFilter(updatedMerchants);
    }
  }, [debounceSearch, list]);

  const handleSelectMerchant = (merch) => {
    
    setUpdatedMerchant(merch);
    setMerchant(merch);
    setContext(merch);
    refresh({hard: true});
    setOpen(false);
  };

  return(
    <Fade in={open} timeout={400}>
      <Box 
        sx={{
          position: 'absolute',
          left: sidebarOpen[0] ? '235px' : '60px',
          borderRadius: '9px',
          top: '0',
          width: '300px',
          boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
          padding: '0 6px',
          backgroundColor: '#fff',
        }}
      >
        {
          <Box>
            <Box sx={{
              position: 'sticky',
              top: '0',
              width: '100%',
            }}>
              <TextInputSimple
                alwaysOn
                placeholder='Buscar'
                icon='search'
                variant='outlined' 
                resettable 
                color='default'
                style={{
                  width: 'calc(100% - 21px)',
                  margin: '12px',
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
            <Box 
              sx={{
                minHeight: '10vh',
                maxHeight: '43vh',
                overflow: 'scroll',
                backgroundColor: '#FFF',
              }}
            >
              { 
                !merchantsFilter ? (
                  <Box sx={{margin: '3px 3px 3px 12px'}}>
                    <Skeleton animation='wave' height={40}/>
                    <Skeleton animation='wave' height={40}/>
                    <Skeleton animation='wave' height={40}/>
                  </Box>
                ) : merchantsFilter.length == 0 ? (
                  <Typography variant='subtitle2' style={{textAlign: 'center', marginTop: '21px'}}>No se encuentran resultados</Typography>
                ) : (
                  <Box>
                    {
                      merchantsFilter.slice(0,maxMerchants).map(merch => (
                        <Box 
                          sx={{
                            padding: '9px 15px',
                            margin: '3px 9px',
                            borderRadius: '12px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: getMerchant()?.id === merch?.id && grey[200],
                            gap: '12px',
                            '&:hover': {
                              backgroundColor: grey[200]
                            }
                          }}
                          onClick={() => handleSelectMerchant(merch)}
                        >
                          {
                            getMerchant()?.id === merch?.id && (
                              <Box 
                                sx={{
                                  width: '10px',
                                  height: '10px',
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: '50%'
                                }}
                              />
                            )
                          }
                          <Typography variant='subtitle2'>{merch.name}</Typography>
                        </Box>
                      ))
                    }
                    {
                      !debounceSearch && list.length > maxMerchants &&  (
                        <Box style={{textAlign: 'center'}}>
                          <Button 
                            variant='text' 
                            size='small' 
                            color='primary' 
                            style={{fontSize: '12px', fontWeight: '700'}}
                            onClick={() => setMaxMerchants(maxMerchants + 25)}
                          >
                          Ver Más 
                          </Button>
                        </Box>
                      )
                    }
                  </Box>
                )
              }
            </Box>
          </Box>
        }
      </Box>
    </Fade>
  );
};

export default MenuContextMerchant;