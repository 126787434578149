import { InputAdornment, TextField, Box, Button, FormHelperText} from '@mui/material';
import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useInput, useListContext } from 'react-admin';
import { HiOutlineSearch } from 'react-icons/hi';
import { theme } from '../../Theme/theme';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { grey } from '@mui/material/colors';
import { Autocomplete, MenuItem, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {CircularProgress} from '@mui/material';
import { DispatchErrorContext } from '../../Context/menuContext';
import { parse, setISOWeek } from 'date-fns';

const COLORS_SEARCH = {
  default: <HiOutlineSearch style={{fontSize: '18px', color: theme.palette.secondary.main}}/>,
  green: <HiOutlineSearch style={{fontSize: '18px', color: theme.palette.green.primary}}/>,
  tickGreen: <CheckCircleIcon style={{fontSize: '18px', color: theme.palette.green.primary}} />,
  inputError: <CancelIcon style={{fontSize: '18px', color: theme.palette.error.light}} />
};

const ICONS = {
  search: COLORS_SEARCH,
};

const ENDADORMENTINPUTS = [
  'date'
];

const TextInputIcon = ({tab, icon, color, minLetters, ...props}) => {
  const {
    field: { name, onChange, ...rest },
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired
  } = useInput(props);
  const [value, setValue] = useState(rest.value);
  const { filterValues } = useListContext();

  const handleChange = (e) => {
    const v = e.target.value;
    setValue(v);
    if(!minLetters || v.length >= minLetters || v.length == 0){
      onChange(e);
    }
  };

  useEffect(() => {
    setValue('');
  }, [tab]);

  useEffect(() => {
    console.log(filterValues[props.source]);
    if(filterValues[props.source] === '' || !filterValues[props.source]) {
      setValue('');
    }
  },[filterValues]);

  return (
    <TextInputSimple 
      onChange={handleChange} 
      name={name} 
      icon={icon}
      color={color}
      label={props.label} 
      required={isRequired}
      {...props}
      {...rest}
      value={value}
      style={{marginBottom: '4px', marginLeft: '24px'}}
    />
  );
};

export const TextInputSimple = ({ name, isDirty, invalid, errors, isTouched, error, icon, color, InputProps, variant, helperText, ...props}) => {
  const open = InputProps?.endAdornment?.props?.ownerState?.popupOpen;
 
  const { getIsLoading, errors: fieldErrors } = useContext(DispatchErrorContext);

  const sourceError = fieldErrors.find(err => err === props.source);
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  const handleDateParser = (value) => {
    const date = value.split('/');
    const formattedDate = `${date[2]}-${date[1]}-${date[0]}`;
    return formattedDate;
  };

  let newHelper = helperText ?? (sourceError !== undefined && `el ${props.source} ingresado ya existe`);

  return(
    <TextField
      InputProps={{
        ...InputProps,
        ...props.InputProps,
        endAdornment: !props.disabled &&
        InputProps?.endAdornment ? (
            <InputAdornment position='end' style={{position: 'relative', right: '-20px', color: grey[600]}}>
              {
                open ?  
                  <KeyboardArrowUpIcon/> : 
                  <KeyboardArrowDownIcon/>
              }
            </InputAdornment>
          ) :
          (props.checkOnblur ? (isTouched && !error && props.type !== 'date' && !getIsLoading()) :
            (isTouched && !error && props.type !== 'date' )) && sourceError === undefined ? 
            COLORS_SEARCH.tickGreen :
            isTouched && !error && getIsLoading() && props?.type !== 'date' ? <CircularProgress size={15} />
              : isTouched && (error || sourceError) && props.type !== 'date'  ? COLORS_SEARCH.inputError : 
                !isTouched ? null : null,

        startAdornment: (
          <InputAdornment position="start" style={{padding: '0 3px'}}>
            {!icon ? null : ICONS[icon][color]}
          </InputAdornment>
        ),
      }}
      fullWidth
      error={!props.disabled && (error || sourceError)}
      style={{...props.style, width: '100%'}}
      variant={variant ? variant : 'outlined'}
      zIndex={2}
      type={props?.type}
      helperText={props?.placeholderText ? props?.placeholderText : !props.disabled ? newHelper : ''}
      initialValue={props.initialValue ?? ''}
      defaultValue={props?.type === 'date' && dateRegex.test(props.defaultValue) ? handleDateParser(props.defaultValue) : props?.defaultValue}
     
      {...props}
    />
  );
};

export const TextAutocomplete = ({label, helperText, optionLabel, onBlur, onChange, inputRef, error, children, item, handleInputChange, icon, color, choices, variant, ...props}) => {
  const handleCallback = useCallback((option) => {
    if(props.central) {
      return '';
    } else {
      return optionLabel ? option?.[optionLabel]: option?.['name'] ? option?.['name'] : option;
 
    }
  },[props.central]);
  
  return (
    <>
      <Autocomplete
        {...props}
        size='medium'
        multiple={props.multiple}
        options={choices}
        disablePortal
        error={error}
        defaultValue={props.initialValue ?? choices?.find(item => item?.id === props.defaultValue)}
        initialValue={props.initialValue ?? choices?.find(item => item?.id === props.initialValue)}
        onChange={(e, v) => onChange(v?.[props.selectedValue] ?? v?.id)}
        onBlur={onBlur}
        helperText={helperText}
        getOptionLabel={handleCallback}
        renderInput={(params) => (
          <TextInputSimple
            icon={icon}
            color={color}
            label={label}
            error={error}
            onChange={onChange}
            helperText={helperText} 
            variant={variant}
            inputRef={inputRef}
            {...params}
          />
        )}
      />
    </>
  );
};

export const TextMultimpleAutoComplete = ({label, value, helperText, optionLabel, onBlur, onChange, inputRef, error, children, item, handleInputChange, icon, color, choices, variant, ...props}) => {

  const [sendValue, setSendValue] = useState([]);

  useEffect(() => {
    value && value?.map(item => {
      setSendValue((f) => ([...f, item.id]));
    });
  },[value]);

  return (
    <>
      <Autocomplete
        {...props}
        value={value}
        multiple
        size='medium'
        options={choices}
        disablePortal
        variant={'outlined'}
        error={error}
        getOptionSelected={(o,v) => o.id === v.id}
        defaultValue={props.defaultValue}
        onChange={(e, v) =>  onChange(v?.[0]?.[props.selectedValue] ?? v)}
        onBlur={onBlur}
        helperText={helperText}
        getOptionLabel={(option) => optionLabel ? option[optionLabel] : option?.name}
        renderInput={(params) => (
          <TextField
            variant={'outlined'}
            label={label}
            {...params}
          />
        )}
        renderTags={(params) => 
          params.map(e => (
            <Typography
              {...params}
              style={{fontSize: '0.8em',
                fontWeight: 400, 
                borderRadius: '5px', 
                padding: '3px', 
                margin: '0 5px 0 5px', 
                backgroundColor: '#e2f1f8'}}>{e?.name}</Typography>
          )
          )}
      />
    </>
  );
};

export const TextSelect = ({optionLabel, initialValue, defaultValue, optionValue, onBlur, onChange, inputRef, error, children, item, handleInputChange, icon, color, choices, variant, ...props}) => {

  const [open, setOpen] = useState(false);

  return(
    <>
      <TextField
        sx={{textTransform: choices?.length === 2 ? 'capitalize' : 'none'}}
        select
        fullWidth
        icon={icon}
        multiple={false}
        color={color} 
        variant={variant ?? 'outlined'}
        onBlur={onBlur}
        onChange={onChange}
        // onClick={() => setOpen(!open)}
        inputRef={inputRef}
        disabled={props.disabled}
        error={error}
        defaultValue={defaultValue?.[optionValue] ?? defaultValue?.[optionLabel] ?? defaultValue}
        initialValue={initialValue?.[optionLabel] ?? initialValue}
        helperText={error?.message}
        SelectProps={{
          backgroundColor: '#fff',
          // open: open,
        }}
        {...props}
      >
        {
          choices && choices?.map((option, index) => (
            <MenuItem
              backgroundColor={'#fff'} 
              sx={{textTransform: choices?.length === 2 ? 'capitalize' : 'none'}} 
              disabled={option.disabled} 
              defaultValue={defaultValue?.[optionValue] ?? option?.[optionValue] ?? defaultValue} 
              initialValue={initialValue?.[optionValue] ?? option[initialValue]} 
              value={option?.[optionValue] ?? option?.[optionLabel] ?? option}
              key={index}
            >
              {optionLabel ? option[optionLabel] : option.name ? option.name : option}
            </MenuItem>
          ))
        }
      </TextField>
    </>
  );
};

export const RadioSelect = ({
  source, 
  optionLabel, 
  initialValue, 
  defaultValue, 
  optionValue, 
  isTouched, 
  onBlur, 
  onChange,
  inputRef, 
  error, 
  helperText,
  children, 
  disabled, 
  item,
  handleInputChange, 
  icon, 
  color, 
  choices, 
  variant, 
  ...props}) => {

  const [selectedValue, setSelectedValue] = useState(props?.checked ? Number(props.checked) : 0);
  const handleAction = (i) => {
    if(i === selectedValue && choices?.length > 1) return;
    if(i === selectedValue && choices?.length === 1) {
      props.onClick && props.onClick(i);
      setSelectedValue(!selectedValue);
      return;
    }
    setSelectedValue(i);
    props.onClick && props.onClick(i);
  };

  return(
    <>
      <Typography style={{fontSize: '14px', ...props.labelStyle}}>{props.label}</Typography>
      <Box style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', borderRadius: '5px', ...props.sx}}>
        {
          choices && choices?.map((item, i) => (
            <>
              <Button
                disabled={disabled}
                fullWidth
                type="button"
                icon={icon}
                multiple={false}
                color={color}
                variant={'outlined'}
                label={item?.name ?? item}
                onBlur={onBlur}
                onChange={onChange}
                onClick={(e) => {handleAction(i);}}
                inputRef={inputRef}
                error={error}
                helperText={error?.message}
                sx={{
                  backgroundColor: 
                  (selectedValue === i) && disabled ?
                    grey[600]  :
                    selectedValue === i ?
                      theme.palette.primary.main  : '',
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: `${choices.length < i}`,
                  width: '20%',
                  
                }}
                // {...props}
              >
                <Typography
                  disabled={disabled}
                  sx={{
                    color: (selectedValue !== i) && disabled ?
                      grey[600] :
                      selectedValue === i ?
                        '#fff' :
                        theme.palette.primary.main,
                    fontSize: '12px',
                    '&:hover': {
                      color: theme.palette.primary.main
                    },
                    ...props.nameStyle
                  }}
                >
                  {item?.name ?? item}
                </Typography>
              </Button>
              
            </>
          ))
        }
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      
    </>
  );
};

export default TextInputIcon;