import { theme } from './theme';
import { grey } from '@mui/material/colors';

const useStyles = {
  booleanInput: {
    '& span': {
      fontSize: '1rem',
      fontWeight: 600,
      fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(','),
      lineHeight: '1.5',
      letterSpacing: '0.01em',
      marginLeft: 0,
    }
  },
  displayNone: {
    display: 'none'
  },
  paddingNone: {
    padding: 0
  },
  font1: {
    fontWeight: '500',
    fontSize: '.9rem'
  },
  marginb: {
    marginBottom: '8px',
  },
};

export const fontStyle = {
  fontWeight: '500',
  fontSize: '1rem'
};

export const styleActions = {
  width: 'calc(100% + 10px)',
  backgroundColor: 'transparent',
  padding: '0px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '30px',
  position: 'stikcy',
  top: 0,
  left: '-10px',
  zIndex: '1100',
};

export const emptyBoxStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0'
};

export const buttonsToolbar = {
  fontSize: '12px',
  padding: '8px 16px',
  textTransform: 'none',
  color: '#0052cc',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#64648c'
  }
};

export const styleChip = {
  paddingLeft: '5px',
  color: grey[900],
  borderColor: grey[900],
};

export const iconsChips = {
  marginRight: '2px',
  color: grey['800']
};

export const fontTitleHeader = {
  fontSize: '20px',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.3',
  color: theme.palette.primary.main,
};

export const styleInnerTitleCard = {
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5',
  color: theme.palette.primary.main,
};

export const fontTextInfoKey = {
  fontWeight: theme.typography.fontWeightBold,
  color: grey[700],
  fontSize: '14px'
};

export const styleSpanPlan = {
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.5',
  color: grey[600]
};

export const emptyTextStyle = {
  fontSize: theme.typography.fontSize,
  color: theme.palette.info.main,
  fontWeight: '600'
};

export const tableCellStyle = {
  borderBottom: '1px solid #e8e8e8',
  padding: '8px',
  color: '#64648c',
  // width: '100%'
};

export const styleTableHead = {
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '1.3',
  color: '#6470B1',
  border: 'none',
  justifySelf: 'start',
  alignSelf: 'start',
  whiteSpace: 'nowrap'
};

export const styleTabbleData = {
  padding: '0 0 8px 18px',
  color: '#64648C',
  border: 'none',
  zIndex: 9999999
};

export const styleButtonSection = {
  fontWeight: '600',
  fontSize: '12px'
};

export const bannerStyle = {
  //backgroundImage: 'url("https://dgkxwewtzsnml.cloudfront.net/static/app-static-assets/cb-vue/cb-vue-2022.01.18-11.36/images/cn-details-customer-banner.svg")',
  position: 'absolute',
  width: '100%',
  height: '225px',
  top: '0',
  backgroundPosition: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  left: '0',
};
