import React, {useEffect} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const ChartPie = ({planes, datasets}) => {

  const options = {
    maintainAspectRatio: false,

  
    scales: {
      x: {
        display: false,
       
      },
      y: {
        display: false,
       
      }
    },
    plugins: {
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(value, data) {
            console.log(datasets);
            if(planes) {
              console.log(value.datasetIndex);
              return [
                value.label + ': ' + value.raw.toLocaleString('es-ar', {
                  style: 'currency',
                  currency: 'ARS'
                }),
                'Cantidad: ' + datasets[value.dataIndex].total
              ];

            };
            return value.label + ': ' + value.raw.toLocaleString('es-ar', {
              style: 'currency',
              currency: 'ARS'
            });
          },
         
        }
      },
      legend: {
        display: false
        // align: 'center',
        // borderRadius: 10,
        // labels: {
        //   boxWidth: 15,
        //   boxHeight: 9,
        //   width: '10px',
        //   padding: 6
        // },
      },
      title: {
        display: false,
      },
    },
  };
  
  const data = {
    labels: datasets && datasets?.map(item => item.key),
    datasets: [
      {
        label: '',
        data: datasets && datasets?.map(item => item.value),
        backgroundColor: [
          '#7FBCD2',
          '#A5F1E9',
          '#E1FFEE',
          '#FFEEAF',
          '#2dc2bd',
        ],
        borderWidth: 1,
      },
    ],
  };


  return (
    <>
      <Doughnut options={options} data={data} />
    </>
  );
};

export default ChartPie;