import MerchantsCreate from './MerchantsCreate';
import MerchantsEdit from './MerchantsEdit';
import MerchantsList from './MerchantsList';
import { BsCash } from 'react-icons/bs';
import { getPermissions } from '../helpers/helpFunctions';
import MerchantsShow from './MerchantsShow';
import { usePermissions } from 'react-admin';

export default{
  icon: BsCash,
  list: MerchantsList,
  create: MerchantsCreate,
  show: MerchantsShow,
  options: (getPermissions() == 'owner' || getPermissions() == 'superadmin') ? 
    {label: 'Negocios', 'menuParent': 'ADMINISTRATIVO'} :
    {label: 'Mi Negocio', 'menuParent': 'ADMINISTRATIVO'}
};