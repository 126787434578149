import {
  Chart as ChartJS,
  LinearScale,
  Title,
  CategoryScale,
  BarElement,
  elements,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React, {useEffect, useState} from 'react';
import { useDataProvider } from 'react-admin';
import { theme } from '../../../../Theme/theme';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import 'chartjs-plugin-datalabels';

const params = {
  pagination: {page: 1, perPage: 9999},
  sort: {field: 'name', order: 'ASC'},
  filter: {}
};

const meses = [ 
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto', 
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];


const mockRechazos = {
  title: 'Rechazos',
  datasets: [
    {
      key: 'Cobros Exitosos',
      value: [Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30)],
    },
    {
      key: 'Aprobados en primer intento',
      value: [Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30)],
    },
    {
      key: 'Rechazados',
      value: [Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30), Math.floor(Math.random() * 30)],
    }
  ]
};

  
const ChartBar = ({egresos, accesos, optionsChart, colors, rechazos, cobros, datasets, rechazosDatasets}) => {

  const dataProvider = useDataProvider();
  const [periods, setPeriods] = useState([]);
  const [ticks, setTicks] = useState([]);
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    elements,
    Tooltip,
    Legend,
    ChartjsPluginStacked100,
    ChartDataLabels,
  );

  useEffect(() => {
    const today = new Date();
    let currentMonth = today.getMonth() ;
    const arr = [];
    while(arr.length <= 11) {
      if(currentMonth <= 11) {
        arr.push(meses[currentMonth]);
        currentMonth === 0 ? currentMonth = 11 : currentMonth--;
        continue; 
      }
      return;
    }
    setPeriods(arr);
  }, []);

  
  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'top',
        labels: {
          value: {
            color: 'black'
          }
        },
        // font: {
        //   weight: 'bold',
        // },
        formatter: function(value, context) {
          if (accesos) {
            return value;
          } else {
            let total = datasets?.chart?.altas[context.dataIndex].value + datasets?.chart?.recurrentes[context.dataIndex].value;
            if(context.dataset.label === 'Recurrentes'){
              let valueString = (total / 1000000).toFixed(2)+ 'M';
            
              return '$ ' + valueString;
            } else {
              return '';
            }
          }
        },
        color: 'black',
        offset: -4,
        clip: false,
      },
      tooltip: {
        callbacks: {
          label: function(value, data) {
            return value.raw.toLocaleString('es-ar', {
              style: 'currency',
              currency: 'ARS'
            });
          },
          
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {usePointStyle: false}

      },
      
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        reverse: true
      },
      y: {
        stacked: true,
        ticks: {
          callback: function(value, index, ticks) {
            return value.toLocaleString('es-ar', {
              style: 'currency',
              maximumFractionDigits: 0,
              currency: 'ARS'
            });
          }
        },
      }, 
      xAxes: [
        
      ]
    },
  };

  const optionsAccesos = {
    maintainAspectRatio: false,
    // aspectRatio: 2,
    indexAxis: 'y',
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'right',
        labels: {
          value: {
            color: 'black'
          }
        },
        color: 'black',
        offset: 0,
        clip: false,
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: false
        }

      },
      
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
        reverse: false,
        afterUpdate: (e) => {
          let scaleTick = e?.ticks?.[1];
          e.max = e?.max + (scaleTick.value / 2);
        },
      },
      y: {
        grid: {
          display: false
        },
      }
    },
  };

  const optionsRechazos = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
        anchor: 'end',
        align: 'top',
        labels: {
          value: {
            color: 'black'
          }
        },
        // font: {
        //   weight: 'bold',
        // },
        formatter: function(value, context) {
          let total = (rechazosDatasets[context.dataIndex].charged_back ?? 0) + (rechazosDatasets[context.dataIndex].approved ?? 0) + (rechazosDatasets[context.dataIndex].rejected ?? 0) + (rechazosDatasets[context.dataIndex].error ?? 0) + (rechazosDatasets[context.dataIndex].expired ?? 0);
          if(context.dataset.label === 'Cobrados en Reintento'){
            let valueString = total;
            if(total > 1000000) {
              valueString = (total / 1000000).toFixed(2)+ 'M';
            }
            return '$ ' + valueString.toLocaleString();
          } else {
            return '';
          }
        },
        color: 'black',
        offset: -4,
        clip: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let price = [];
            rechazosDatasets?.map((e, i) => {
              price.push({retry: e?.approved + (e?.charged_back ?? 0), never: (e?.rejected ?? 0) + (e?.error ?? 0) + (e?.expired ?? 0)});
            });
            if(context.dataset.label === 'Cobrados en Reintento') {
              return [
                context.raw.toFixed(2).toLocaleString() + ' %',
                `$ ${price[context.dataIndex].retry}`
              ];
            } else {
              return [
                context.raw.toFixed(2).toLocaleString() + ' %',
                `$ ${price[context.dataIndex].never}`
              ];
            }
          },
        }
      },
      stacked100: {
        enable: false
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            family: 'Helvetica',
            weight: '400',
          }
        }
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        reverse: true,
        
      },
      y: {
        stacked: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value, index, ticks) {
            
            return value + '%';
          }
        }
      },
      yAxes: [
        {
          display: true,
          labels: {
            show: true
          },
          ticks: {
            maxTicksLimit: 100,
          }
        }
      ]
    },
    
  };

  let newTotal = 0;
  useEffect(() => {
    !accesos && !egresos && datasets?.chart?.altas.map(e => newTotal = newTotal + e?.value);
  }, []);

  const dataChartRechazos = rechazos && rechazosDatasets && {
    labels: periods.map(item => item),
    datasets: [
      
      {
        label: 'No cobrados',
        data: rechazosDatasets?.map((e, i) => (Math.floor((e?.rejected ?? 0) + (e?.error ?? 0) + (e?.expired ?? 0)) / Math.floor(rechazosDatasets[i]?.totales - (rechazosDatasets[i]?.in_process ?? 0)) * 100)),
        backgroundColor: '#ef9a9a',

      },
      {
        label: 'Cobrados en Reintento',
        data: rechazosDatasets?.map((e, i) => ((e?.approved + (e?.charged_back ?? 0))/ Math.floor(rechazosDatasets[i]?.totales - (rechazosDatasets[i]?.in_process ?? 0)) * 100)),
        backgroundColor: '#26c6da',
        borderSkipped: false,
      }
    ]
  };
  
  const dataChart = cobros && {
    labels: periods.map(item => item),
    datasets: [
      {
        label: 'Altas',
        data: datasets?.chart?.altas.map(e => e?.value),
        backgroundColor: '#26c6da',
        bordereColor: '#26c6da',
        stack: 'Stack 0',
        borderSkipped: false,
      },
      {
        label: 'Recurrentes',
        data: datasets?.chart?.recurrentes.map(e => e?.value),
        backgroundColor: '#64b5f6',
        stack: 'Stack 0',
        bordereColor: '#006064',
        borderSkipped: false,
       
      }
    ],
  };

  const dataChartIngresos = accesos && {
    labels: ['Propios', 'Sedes', 'Centrales'],
    datasets: [
      {
        label: 'Ingresos',
        data: [datasets?.chart?.accesos, datasets?.chart?.sedes, datasets?.chart?.centrales],
        backgroundColor: ['#bae48f', '#26c6da', '#e1cff3'],
        bordereColor: '#26c6da',
        stack: 'Stack 0',
        borderSkipped: false,
      },
    ],
  };

  const dataChartEgresos = egresos && {
    labels: datasets?.chart?.accesos?.map(item => item[0]),
    datasets: [
      {
        label: 'Ingresos',
        data: datasets?.chart?.accesos?.map(item => item[1]),
        backgroundColor: ['#bae48f', '#26c6da', '#e1cff3', '#dee080', '#7dafdf','#df7c95', '#df7cb5'],
        bordereColor: '#26c6da',
        stack: 'Stack 0',
        borderSkipped: false,
      },
    ],
  };
  
  return (
    <>
      {
        cobros && (
          <Bar options={options} data={dataChart} />
        )}
      {
        rechazos && rechazosDatasets && (
          <Bar options={optionsRechazos} data={dataChartRechazos} />
        )
      }
      {
        accesos && (
          <Bar options={optionsAccesos} data={dataChartIngresos} />
        )
      }
      {
        egresos && (
          <Bar options={optionsAccesos} data={dataChartEgresos} />
  
        )
      }
    </>
  );
};
  
export default ChartBar;