import React, { useState } from 'react';
import { Box, Collapse, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { emptyTextStyle, styleTabbleData, tableCellStyle } from '../../../Theme/useStyle';
import { FiExternalLink } from 'react-icons/fi';

const TableMinimal = ({
  data,
  display = 'flex',
  align = 'right',
  noBorderBottom, 
  textAlign='right',
  widthBoxKey = 'auto',
  emptyText = 'No se encuentran registros',
  minWidth = 500,
  sx,
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  return (
    <Box sx={sx}>
      {
        data?.length > 0 ? (
          <TableContainer>
            <Table sx={{ minWidth: minWidth  }}>
              <TableBody >
                {data.map(item => {
                  if(item) {
                    if(item.key) {
                      return (
                        <TableRow key={item.key}>
                          <TableCell 
                            component="th" 
                            scope="row" 
                            style={noBorderBottom ? {...tableCellStyle, borderBottom: 'none'} : tableCellStyle}
                          >
                            <Box style={{width: widthBoxKey}}>
                              {item?.key}
                            </Box>
                          </TableCell>
                          {
                            item.link ? (
                              <TableCell 
                                align={align} 
                                style={noBorderBottom ? 
                                  {...tableCellStyle, borderBottom: 'none'} : 
                                  {...tableCellStyle, color: '#525252', width: '80%'}}
                              >
                                <Link underline='none'
                                  href={item.value}
                                  target='_blank'
                                  style={{fontWeight: '600', fontSize: '13px'}}
                                >
                                Link <FiExternalLink/>
                                </Link>
                              </TableCell>
                            ) : item.custom ? (
                              <TableCell 
                                align={align}
                                style={noBorderBottom ?  
                                  {...tableCellStyle,  display: 'flex', borderBottom: 'none'} : 
                                  {...tableCellStyle, display: 'flex', color: '#525252'}}
                              >
                                <Box style={{textAlign: textAlign}}>
                                  {item.value}
                                </Box>
                              </TableCell>
                            ) : (
                              <TableCell 
                                align={align}
                                style={noBorderBottom ?  
                                  {...tableCellStyle, borderBottom: 'none'} : 
                                  {...tableCellStyle, color: '#525252'}}
                              >
                                <Box style={{textAlign: textAlign}}>
                                  {item?.value?.length > 0 ? item.value : '...'}
                                </Box>
                              </TableCell>
                            )
                          }
                        </TableRow>
                      );
                    }
                    if(item.collapse) {
                      return(
                        <>
                          <TableRow sx={{width: '100%', ...item.sx}} key={item.label}>
                            <TableCell 
                              // component="th" 
                              scope="row" 
                              style={noBorderBottom ? {...tableCellStyle, borderBottom: 'none', width: '100%'} : tableCellStyle}
                            >
                              <Box style={{width: widthBoxKey}}>
                                {item?.label}
                              </Box>
                            </TableCell>
                            <TableCell 
                              align={align}
                              style={noBorderBottom ?  
                                {...tableCellStyle, borderBottom: 'none'} : 
                                {...tableCellStyle, color: '#525252'}}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                  item.collapseAction && item.collapseAction();
                                  setOpenCollapse(!openCollapse);
                                }}
                              >
                                {item?.open ?? openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow colSpan={6} sx={{boxSizing: 'content-box', width: 'inherit'}} key={'collapsed'}>
                            <TableCell scope="row" style={{borderBottom: 'none', width: '100%'}} colSpan={6} key={'div_inside'} >
                              <Collapse scope="row" sx={{width: '100%'}} in={item.open ?? openCollapse} >
                                {item?.value}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                          
                        </>
                      );
                    }
                  }
                }

                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box style={{display: 'flex', justifyContent: 'center'}}>
            <Typography variant='subtitle2'>{emptyText}</Typography>
          </Box>
        )
      }
    </Box>
  );
};

export default TableMinimal;