import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { Box } from '@mui/material';
import ActividadesCreate from './ActividadesCreate';
import ActividadesEdit from './ActividadesEdit';
import ActividadesList from './ActividadesList';
import ActividadesShow from './ActividadesShow';

export default{
  icon: DirectionsRunIcon,
  list: ActividadesList,
  create: ActividadesCreate,
  show: ActividadesShow
};