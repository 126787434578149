import WifiIcon from '@mui/icons-material/Wifi';
import AmenitiesCreate from './AmenitiesCreate';
import AmenitiesEdit from './AmenitiesEdit';
import { AmenitiesList } from './AmenitiesList';
import AmenitiesShow from './AmenitiesShow';

export default{
  icon: WifiIcon,
  list: AmenitiesList,
  edit: AmenitiesEdit,
  create: AmenitiesCreate,
  show: AmenitiesShow
};