import { Box, Collapse, Divider, Fade, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DeleteWithConfirmButton, useRedirect } from 'react-admin';
import { createNotification, handleClickCopy } from '../../../Resources/helpers/helpFunctions';
import { backgroundColor, theme } from '../../../Theme/theme';
import { ButtonToolbar } from '../Buttons/ButtonToolbar';

const asideActionsStyles = {
  position: 'sticky',
  width: 'calc(25% - 10px)',
  minWidth: '230px',
  height: '100%',
  marginTop: '110px',
  top: 0,
  padding: '60px 0'
};

const AsideActionsShow = ({data}) => {
  const redirect = useRedirect();

  const handleSuccess = (record, basePath) => {
    createNotification('success', `${record.name} eliminado con éxito`);
    redirect(basePath);
  };

  const handleError = (record, basePath) => {
    createNotification('error', `${record.name} no se pudo eliminar, por favor intente más tarde`, ()=> handleClickCopy(record));
  };

  return (
    <Box style={asideActionsStyles}>
      <Box style={{backgroundColor: backgroundColor, borderRadius: '8px',padding: '12px 0'}}>
        <Box display={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start'}}>
          {
            data && data.map(item => {
              if(item){
                return(
                  item.divider ? (
                    <Divider key={Math.random()} style={{width: '85%', margin: '2px auto 6px auto'}}/>
                  ) : item.custom ? (
                    item.component
                  ) : item.confirmTitle ? (
                    item.tooltip ? 
                      <Tooltip title={item.tooltip} placement="bottom" arrow>
                        <Box>

                          <DeleteWithConfirmButton 
                            {...item.rest} 
                            key={Math.random()}
                            disabled={item.disabled}
                            fullWidth 
                            style={{justifyContent: 'flex-start', padding: '9px 26px'}}
                            mutationMode="pessimistic"
                            label={item.label} 
                            color={item.color}
                            variant='text'
                            confirmTitle={item.confirmTitle}
                            confirmContent={item.confirmContent} 
                            record={item.record}
                            onSuccess={() => handleSuccess(item.record, item.basePath)}
                            onFailure={() => handleError(item.record, item.basePath)}
                            undoable={true}
                          />
                        </Box>
                        
                      </Tooltip>
                      :
                      <DeleteWithConfirmButton 
                        {...item.rest} 
                        key={Math.random()}
                        disabled={item.disabled}
                        fullWidth 
                        style={{justifyContent: 'flex-start', padding: '9px 26px'}}
                        mutationMode="pessimistic"
                        label={item.label} 
                        color={item.color}
                        variant='text' 
                        confirmTitle={item.confirmTitle}
                        confirmContent={item.confirmContent} 
                        record={item.record}
                        onSuccess={() => handleSuccess(item.record, item.basePath)}
                        onFailure={() => handleError(item.record, item.basePath)}
                        undoable={true}
                      />
                  ) : (
                    <ButtonToolbar
                      href={item.href}
                      key={Math.random()}
                      startIcon={item.icon} 
                      onClick={item.link ? () => redirect(item.link) : item.onClick}
                      target={item.noBlank ? null : '_blank'}
                      style={item.style ? 
                        item.style : 
                        {padding: '9px 26px', fontWeight: '600', textTransform: 'none', color: item.color}
                      }
                      disabled={item.disabled}
                      fullWidth
                    >
                      {item.text}
                    </ButtonToolbar>
                  )
                );
              }
            })
          }
        </Box>
      </Box>
    </Box>
  );
};

export default AsideActionsShow;