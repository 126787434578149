export const ROUTE_NAMES = [
  {key: 'users', value: 'Usuario'},
  {key: 'merchants', value: 'Negocio'},
  {key: 'socios', value: 'Socio'},
  {key: 'planes', value: 'Plan'},
  {key: 'multinegocio', value: 'Plan'},
  {key: 'sedes', value: 'Sede'},
  {key: 'amenities', value: 'Servicio'},
  {key: 'actividades', value: 'Actividad'},
  {key: 'corporativo', value: 'Campaña'},
  {key: 'punto_acceso', value: 'Punto de Acceso'}

];