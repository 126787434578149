import * as React from 'react';
import { useRecordContext, useRedirect, useResourceContext } from 'react-admin';
import LaunchIcon from '@mui/icons-material/Launch';
import { useContext } from 'react';
import { merchantCadena, merchantContext } from '../../DataProvider';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import { ActionsDiv, MenuActionsDiv } from '../UI/Options/ActionsOptions';
import { useRef } from 'react';
import { useState } from 'react';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import { Box } from '@mui/material';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
  icon: {
    width: '0.6em',
    height: '0.6em',
    paddingLeft: 2,
  },
});

const OpenLink = ({ source, prepend, cta = '', disabled, viewContext,...props }) => {

  const [showActions, setShowActions] = useState(false);
  const { getMerchant, setMerchantHabilitado } = useContext(merchantContext);
  const resource = useResourceContext();
  const record = useRecordContext();
  const ref = useRef();

  const redirect = useRedirect();
  useOnClickOutside(ref, () => setShowActions(false));
  
  const isMultinegocio = resource == 'multinegocio';
  const disabledPlanLink = resource == 'planes' && (!record.payment_methods?.tarjeta && record.payment_methods?.efectivo) ? true : false;


  const handleClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const handleRedirect = (e, prep) => {
    e.stopPropagation();
    redirect(`/socios?displayedFilters=%7B"${prep.filter}"%3Atrue%7D&filter=%7B"${prep.filter}"%3A"${record?.id}"%7D&order=DESC&page=1&perPage=25&sort=last_subscription_date`);
  };

  const handleLink = (tipo, merchId) => {
    console.log('ASdasd');
    switch(true){
    case resource === 'corporativo':
      if (merchId !== '' && merchId !== merchantCadena && ['cadena-habilitadas', 'cadena-sede'].includes(tipo)) {
        return '?seller_merchant='+ merchId;
      }
      return '';
    case resource === 'planes': 
      if (merchId !== '' && merchId !== merchantCadena) {
        return '?seller_merchant=' + merchId;
      }
      return '';
    
    case resource === 'abandoned':
      if (merchId !== '' && merchId !== merchantCadena) {
        return '?seller_merchant='+ merchId;
      }
      return '';
    }
  };

  return (disabledPlanLink || record?.archivado) ? (
    null
  ) : (
    <MenuActionsDiv ref={ref}>
      <MenuIcon 
        sx={{color: '#64648C', borderRadius: '4px', cursor: 'pointer', '&:hover':{ backgroundColor: '#cfd8dc'}}} 
        onClick={handleClick}
      />
      <ActionsDiv sx={{top: null, left: null, display: showActions ? 'flex' : 'none', transform: 'translate(-50%, 50%)'}} id='actions_div'>
        {
          prepend.map(prep => (
            prep.redirect ? (
              <>
                <Button
                  sx={{
                    color: 'grey',
                    '&:hover': {
                      color: '#000'
                    }
                  }} 
                  fullWidth
                  target="_blank"
                  onClick={(e) => handleRedirect(e, prep)}
                >
                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <Typography
                      variant='subtitle2'
                      style={{fontSize: '11px'}} 
                      color='primary'
                    >
                      {prep.label} 
                    </Typography>
                    <LaunchIcon style={{width: '14px', backgroundColor: 'priamry'}} color='priamry'/>
                  </Box>
                </Button>
              </>
            ) : (
              <>
                <Button
                  sx={{
                    color: 'grey',
                    '&:hover': {
                      color: '#000'
                    }
                  }} 
                  fullWidth
                  target="_blank"
                  onClick={handleClick}
                  href={(record && source) ? (
                    prep.viewContext ? 
                      `${prep.link+record[source]}${handleLink(record?.tipo, getMerchant()?.id)
                        // ((getMerchant().id !== '' && resource !== 'corporativo' && getMerchant()?.id !== merchantCadena) ?
                        // '?seller_merchant=' + getMerchant().id : '' )
                      }` :
                      prep.link+record[source]
                  ) :
                    record?.corporativo ? prep.link+record?.corporativo : 
                      resource === 'abandoned' ? prep.link+record?.plan+handleLink(null, getMerchant()?.id) : prep.link+record?.plan
                  } 
                >
                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <Typography
                      variant='subtitle2'
                      style={{fontSize: '11px'}} 
                      color='primary'
                    >
                      {prep.label} 
                    </Typography>
                    <LaunchIcon style={{width: '14px', backgroundColor: 'priamry'}} color='priamry'/>
                  </Box>
                </Button>
              </>
            )
          ))
        }
      </ActionsDiv>
      
    </MenuActionsDiv>
  );
};

export default OpenLink;

