import { Refresh } from '@mui/icons-material';
import { Box, makeStyles, styled, withStyles } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Create, Form, SimpleForm, Toolbar, useRedirect, useRefresh} from 'react-admin';
// import { FormTransparent } from '../../Components/CustomFields/FormTransparent';
import { ToolbarCustomButton } from '../../Components/UI/ButtonsToolbar';
import { backgroundColor } from '../../Theme/theme';
import AccesoCreateEdit from './AccesoCreateEdit';

const CustomToolbar = ({data, setSendData,refresh, redirect, setSocio }) => {
  return(
    <Toolbar sx={{backgroundColor: backgroundColor, marginTop: '110px'}}>
      <ToolbarCustomButton redirect={redirect} setSocio={setSocio} refresh={refresh} data={data} setSendData={setSendData} />
    </Toolbar>
  );
};



export const FormTransparent = styled(SimpleForm)({
  backgroundColor: backgroundColor,
  display: 'flex',
  flexDirection: 'row',
  '& .MuiCardContent-root': {
    width: '75%',
    paddingLeft: '5rem'
  },
  
});


const AccesosCreate = (props) => {
  const [sendData, setSendData] = useState({'source': 'buenclub'});
  const [socio, setSocio] = useState(null);

  const refresh = useRefresh();
  const redirect = useRedirect();
  useEffect(() => {
    redirect('create', 'accesos');
  },[refresh]);
  return (
    <Create title='Crear Acceso' mutationMode="pessimistic">
      <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'start', justifyContent: 'center', paddingTop: '50px', backgroundColor: backgroundColor}}>
        <AccesoCreateEdit sx={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}} socio={socio} setSocio={setSocio} sendData={sendData} setSendData={setSendData} isCreate />
        <CustomToolbar redirect={redirect} refresh={refresh} data={sendData} setSocio={setSocio} setSendData={setSendData} />
      </Box>
    </Create>
  );
};

export default AccesosCreate;
