
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { SidebarToggleButton, LoadingIndicator } from 'react-admin';
import { getToken } from '../../../Resources/helpers/helpFunctions';
import UserMenuCustom from './UserMenuCustom';
import { DispatchErrorContext } from '../../../Context/menuContext';
import { AppBar, Box, IconButton, Tooltip } from '@mui/material';
import JoyRide from '../../../Components/JoyRide/JoyRide';
import { IoIosNotificationsOutline } from 'react-icons/io';
import NotificationButton from '../../Notifications';

const AppbarCustom = (props) => {
  const { setOpenMenu, getStateMenu } = useContext(DispatchErrorContext);
  const [movementScroll , setMovementScroll] = useState(false);
  const [index,setIndex]= useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    if (index === 1) {
      const scrollContainer = document.getElementById('scrollableDivJoyRide');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }
    if (index === 10) {
      const scrollContainer = document.getElementById('scrollableDivJoyRide');
      if (scrollContainer) {
        scrollContainer.scrollTop = 500;
      }
    }
  
    if (movementScroll) {
      const scrollContainer = document.getElementById('scrollableDivJoyRide');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
        setMovementScroll(false);
      }
    }
  }, [index]);

  let tokenDecode = getToken();
  
  return (
    <AppBar  {...props} color='secondary'>
      <Box
        sx={{
          minHeight: '48px',
          padding: '0 24px 0 6px',
          display: 'flex',
          justifyContent: 'space-between', 
          alignItems: 'center',
        }}
      >
        <SidebarToggleButton/>
        <Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}>
          {/* <NotificationButton /> */}
          <JoyRide setMovementScroll={setMovementScroll} setIndex={setIndex} index={index}/>
          <LoadingIndicator/>
          <UserMenuCustom token={tokenDecode} {...props}/>
        </Box>
      </Box>
    </AppBar>
  );
};

export default AppbarCustom;