import { Box, Drawer, SwipeableDrawer, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import AppbarCustom from '../Appbar/AppbarCustom';
import MenuCustom from './Menu/MenuCustom';
import { createNotification } from '../../../Resources/helpers/helpFunctions';
import { snippetBeacon } from '../../Snippets/Snippets';
import { Layout, SidebarClasses, useLocaleState, useSidebarState } from 'react-admin';

const LayoutCustom = (props) => {

  useEffect(() => {
    snippetBeacon();
  }, [window.location.href]);

  return (
    <Layout {...props} menu={MenuCustom} appBar={AppbarCustom} error={(error) => <ErrorPage {...error}/>}/>
  );
};

export default LayoutCustom;

export const ErrorPage = ({error, errorInfo, ...rest}) => {

  useEffect(() => {
    createNotification('error', 'Ups, algo salio mal, reintente mas tarde');
  }, []);

  return(
    <Box sx={{display: 'flex', margin: '120px auto'}}>
      {/* <Typography>{errorInfo.componentStack.split('\n').slice(0,10).join('\n')}</Typography> */}
    </Box>
  );
};
