import CentralIcon from '../../../Assets/Images/man-lifting-weight.png';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import PlanCreate from '../PlanCreate';
import { PlanList } from '../PlanList';
import PlanesShow from '../PlanesShow';



export default {
  icon: ModeStandbyIcon,
  create: PlanCreate,
  show: PlanesShow,
  list: PlanList
};