import { useRecordContext } from 'react-admin';
import { GrCertificate } from 'react-icons/gr';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import afipMerchant from '../Edit/AfipMerchant';


const AfipCard = () => {
    
  const record = useRecordContext();
  
  const detailsArray = record && [
    {key: 'Cuit', value: record.afip?.cuit},
    {key: 'Provincia', value: record.afip?.domicilio?.provincia?.toUpperCase()},
    {key: 'Localidad', value: record.afip?.domicilio?.localidad?.toUpperCase()},
    {key: 'Calle', value: record.afip?.domicilio?.calle ? record.afip?.domicilio?.calle : '' + ' ' + record.afip?.domicilio?.altura ? record.afip?.domicilio?.altura : ''},
    {key: 'II BB', value: record.afip?.ii_bb},
    {key: 'Apto Lote', value: record.afip?.domicilio?.apto_lote},
    {key: 'Proceso de facturacion para planes', value: record?.afip?.activate_facturacion ? 'Habilitado' : 'Deshabilitado'},
  ];
    
  const editComponent =  {
    component: afipMerchant(),
    label: 'Datos de AFIP',
    icon: <GrCertificate/>
  };

  return (
    <CardBasic scrollable route={'merchants'} editComponent={editComponent} record={record} title='Facturación'>
      <TableMinimal data={detailsArray}/>
    </CardBasic>
  );
};

export default AfipCard;