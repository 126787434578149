import { Box } from '@mui/material';
import { Show, useDataProvider, usePermissions, useRecordContext, useRefresh, useResourceContext } from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import { bannerStyle } from '../../Theme/useStyle';
import AfipCard from './Show/AfipCard';
import CredentialsCard from './Show/CredentialsCard';
import MerchantHeader from './Show/MerchantHeader';
import PlanesCard from './Show/PlanesCard';
import TyCMerchantCard from './Show/TyCMerchantCard';
import AplicationCard from './Show/AplicationCard';



const MerchantsShow = (props) => {

  return (
    <Show 
      {...props} 
      title={<TitleEdit title='Negocios: ' source='name'/>} 
      aside={<AsideShow />} 
      actions={null}
    >
      <ShowTransparent>
        <Box style={bannerStyle}/>
        <MerchantHeader />
        <AfipCard />
        <AplicationCard />
        {/* <PlanesCard /> */}
        <CredentialsCard />
        <TyCMerchantCard />
      </ShowTransparent>
    </Show>
  );
};

export default MerchantsShow;

export const AsideShow = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const permis = usePermissions().permissions;
  const refresh = useRefresh();
  const resource = useResourceContext();
  const aside = record ? [
    (permis === 'superadmin' || permis === 'owner') && {
      record: record,
      disabled: record?.credentials?.mercadopago.active,
      label: 'Eliminar Negocio',
      confirmTitle: `¿Eliminar ${record?.name}?`,
      confirmContent: '¿Seguro quieres eliminar este Negocio?',
      basePath: resource,
    }
  
  ] : [];
  
  return (
    <AsideActionsShow data={aside}/>
  );
};