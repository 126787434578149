
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { defaultTheme, ListClasses } from 'react-admin';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const widthMenu = '230px';

export const backgroundColor = '#f1f1f1';

export const breakpoints = createBreakpoints({ ...customBreakpointValues });

export const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#5584AC',
      light: '#5584AC'
    },
    secondary: {
      main: '#22577E',
      light: '#4E9AD0'
    },
    info: {
      main: '#64648C',
    },
    success:{
      main: '#2e7d32'
    },
    disabled: {
      main: '#7E7E7E',
      light: '#D7D7D7',
      blue: '#b0bec5',
      background: '#eceff1'
    },
    text: {
      main: '#5584AC',
    },
    background: {
      paper: '#fff',
    },
    grey:{
      ...grey
    },
    green:{
      primary: '#4BC0C0'
    },
    red:{
      primary: '#E46464'
    },
    error: {
      light: '#e57373',
      main: '#d32f2f',
      dark: '#a11313',
      contrastText: '#fff'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    }
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Raleway', 'sans-serif'].join(','),
    fontWeightBold: 600,
  },
  
  components: {
    ...defaultTheme.components,
    RaCardContentInner: {
      styleOverrides:{
        root: {
          backgroundColor: 'transparent',
        }
      }
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          height: '100%',
          display: 'flex',
          alignItems: 'center'
          
        }
      }
    },
    RaLayout: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColor,
          [breakpoints.up('xs')]:{
            backgroundColor: backgroundColor,
          },
          '& .RaLayout-content': {
            top: '0',
            marginTop: '0',
            padding: '0',
            paddingTop:'0',
            width: 'auto',
            backgroundColor: backgroundColor,
            [breakpoints.up('xs')]:{
              backgroundColor: backgroundColor,
              paddingLeft: '0'
            }
          }
        },
        appFrame: {
          [breakpoints.up('xs')]:{
            marginTop: '0px'
          }
        }
      }
      
    },
    
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          margin: 0
        }
      }
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          display: 'flex',
          zIndex: '99',
          backgroundColor: '#fff',
          maxWidth: widthMenu,
          '& .RaSidebar-fixed': {
            top: 0,
            left: 0,
            paddingTop: '60px',
            backgroundColor: '#fff',
            height: '100vh',
            maxWidth: widthMenu,
            boxShadow: '1px 0 2px 0 rgba(163 163 163 / 8%), 6px 0 10px 0 rgba(163 163 163 / 8%)',
            borderRadius: '0 10px 10px 0',
            overflowX: 'visible'
          },
          '& .RaSidebar-drawerPaper': {
            maxWidth: widthMenu,
          }
        },
      }
    },
    RaMenu: {
      styleOverrides: {
        root: {
          maxWidth: widthMenu,
          '& .RaMenu-open': {
            maxWidth: widthMenu,
          },
          '& .RaMenu-closed': {
            maxWidth: '55px',
          }
        }
      }
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          '&.RaMenuItemLink-active': {
            backgroundColor: grey[200],
            color: '#5584AC !important',
            '& .MuiListItemIcon-root':{
              color: '#5584AC',
            }
          },
          '& .MuiListItemIcon-root':{
            fontSize: '1rem',
            '& .MuiSvgIcon-root':{
              fontSize: '1rem'
            },
          }
        },
      }
    },
    RaLoading: {
      container: {
        marginTop: '0',
        backgroundColor: backgroundColor,
        [breakpoints.up('xs')]:{
          marginTop: '0',
        }
      }
    },
    RaShow: {
      styleOverrides: {
        root: {
          margin: '0 auto',
          '& .RaShow-main': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '1125px',
            margin: '0 auto',
            padding: '0px 20px 20px 20px',
            backgroundColor: backgroundColor
          },
          '& .RaShow-card': {
            marginTop: '30px',
            flex: 'none',
            width: '75%',
            minWidth: '650px',
            backgroundColor: backgroundColor
          }, 
          '& .RaShow-noActions': {
            marginTop: '0'
          }
        },
      },
    },
    RaEdit: {
      noActions: {
        marginTop: 0
      },
      main: {
        marginTop: '0px',
        margin: '20px auto',
        maxWidth: '1200px',
        padding: '20px'
      },
      card:{
        maxWidth: '1200px'
      }
    },
    RaCreate:{
      main: {
        marginTop: '0px',
        margin: '0 auto',
        maxWidth: '1200px',
        padding: '20px'
      },
      noActions: {
        marginTop: '64px',
        [breakpoints.up('xs')]:{
          marginTop: '64px',
        }
      },
      card:{
        maxWidth: '1200px'
      }
    },
    RaList: {
      styleOverrides:{
        root:{
          //paddingTop: '50px',
          '& .RaList-main':{
            margin: '24px'
          },
          '& .RaList-content':{
            boxShadow: 'none'
          },
        },
      }
    },
    RaListToolbar: {
      styleOverrides:{
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          left: '-10px',
          width: 'calc(100% + 10px)',
          [breakpoints.up('xs')]:{
            padding: '0px 40px',
            paddingLeft: '48px'
          },
          actions: {
            minHeight: 'auto',
            paddingTop: 0,
            padding: ' 0px 0px 0px 0px',
            alignItems: 'center'
          }
        },
    
      },
    },
    RaTopToolbar: {
      styleOverrides:{
        root: {
          minHeight: 'auto',
          paddingTop: 0,
          padding: ' 0px 0px 0px 0px',
          alignItems: 'center'
        },
    
      },
    },
    RaTabbedForm: {
      content: {
        width: '100%'
      }
    },
    RaToolbar: {
      toolbar: {
        '& .RaToolbar-toolbar' :{
          backgroundColor: 'transparent',
          marginTop: '0',
          display: 'flex',
          padding: '24px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }
      },
      desktopToolbar: {
        marginTop: '0'
      }
    },
    RaNotification: {
      success: {
        color: '#fff'
      }
    },
    RaAutocompleteArrayInput: {
      suggestionsContainer: {
        position: 'relative',
        borderRadius: '10px',
        backgroundColor: '#fff',
        border: '1px solid #d1d1d1',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        overflow: 'hidden',
        paddingTop: '0px',
        top: '0px !important',
      },
      chipContainerOutlined: {
        margin : 'px 0',
      },
      chip: {
        height: '24px'
      }
    },
    RaTreeCustomMenuItem: {
      menuItemName:{
        fontWeight: '600',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.95rem'
      }
    },
    RaAutocompleteInput: {
      container: {
        flexGrow: '0'
      },
      suggestionsContainer: {
        borderRadius: '10px',
        backgroundColor: '#fff',
        border: '1px solid #d1d1d1',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        overflow: 'hidden',
        paddingTop: '0px',
        marginLeft: '20px'
      }
    },
    RaCheckboxGroupInput: {
      '& .MuiFormGroup-row' : {
        fontSize: '13px'
      }
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-row': {
            '& td:first-child': {
              borderTopLeftRadius: '9px',
              borderBottomLeftRadius: '9px',
            },
            '& td:last-child': {
              borderTopRightRadius: '9px',
              borderBottomRightRadius: '9px'
            },
            backgroundColor: '#fff',
            height: '65px'
          },
          '& .RaDatagrid-headerCell': {
          // top: '42px',
            padding: '0 16px',
            backgroundColor: backgroundColor,
            color: '#555'
          },
          '& .RaDatagrid-headerRow': {
            height: '40px !important',
            backgroundColor: backgroundColor,

          },
        },
      },
    },
    RaDeleteWithConfirmButton: {
      deleteButton: {
        fontSize: '14px',
        padding: '7px 23px',
        border: 'none',
        lineHeight: '1.75',
        cursor: 'pointer',
        display: 'flex',
        color: '#d4483d',
        textTransform: 'none',
        fontWeight: '600',
        fontFamily: 'Roboto,Helvetica, Arial, sans-serif',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#993831'
        }
      }
    },
    RaResettableTextField: {
      clearButton: {
        padding: '0 4px'
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiTypography: {
      root:{
        fontFamily:  ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Raleway', 'sans-serif'].join(','),
      },
      body1: {
        fontWeight: 700,
        fontSize: '1.2rem',
        marginLeft: '10px'
      },
    },
    MuiTabs: {
      flexContainer: {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    MuiList: {
      root: {
        backgroundColor: '#fff'
      }
    },
    MuiListItemIcon:{
      root: {
        '& svg': {
          fontSize: '18px'
        }
      }
    },
    MuiToolbar: {
      stylesOverride: {
        toolbar : {
          '& .MuiToolbar-toolbar' :{
            backgroundColor: 'transparent',
            marginTop: '0',
            display: 'flex',
            padding: '24px',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }
        }
      }
    },
    MuiTablePagination: {
      toolbar: {
        backgroundColor: backgroundColor
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '9px solid' + backgroundColor,
          '& .MuiTableCell-sizeSmall': {
            height: '30px',
            padding: '1px 8px 1px 16px'
          },
          '& .MuiTableCell-head': {
            fontSize: '.75rem',
            textTransform: 'uppercase',
            color: '#000'
          }
        },
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none'
      },
      elevation1: {
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: '10px',
      }
    },
    MuiPaginationItem: {
      root: {
        padding: '0 12px'
      }
    },
    MuiCardContent: {
      root: {
        backgroundColor: backgroundColor,
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      root: {
        minWidth: '30px',
      },
      textSizeSmall: {
        fontSize: '0.875rem'
      },
      outlinedPrimary: {
        color: '#1976d2'
      }
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked': {
          color: '#1976d2',
          track: {
            backgroundColor: '#1976d2'
          }
        }
      },
    },
    MuiFormGroup: {
      root: {
        height: 'auto',
        backgroundColor: 'transparent',
        minHeight: '50px'
      }
    },
    MuiFormControlLabel: {
      root: {
        minHeight: '50px',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginLeft: '0px',
        fontWeight: '500',
        fontSize: '16px'
      },
      label: {
        fontSize: '1rem !important',
        fontWeight: '400 !important',
      }
    },
    MuiGrid: {
      root: {
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '0px'
      },
      item: {
        width: '100%'
      }
    },
   
  },
});