import { Box, Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ButtonAws from '../../../Components/CustomFields/ButtonAws';
import SectionForm from '../../../Components/Form/SectionForm';
import { required, useRecordContext } from 'react-admin';
import { createNotification } from '../../helpers/helpFunctions';

const tycCustom = () => {
  const record = useRecordContext();
  const isEdit = record ? true : false;
  const [url, setUrl] = useState(null);
  
  const handleTyC = (url) => {
    setUrl(url);
    // form.change('default_tyc', url);
  };

  const data = [
    {
      custom: true,
      component: <Box style={{display: 'flex', gap: '20px'}}>
        <Box>
          <ButtonAws variant='contained' finish={handleTyC} text='Subir TyC' small accept='.pdf, .txt'/>
        </Box>
        {
          ((url) || (isEdit && record?.default_tyc)) && (
            <Button size='small' variant='outlined' href={url ? url : record?.default_tyc} target='_blank'>Ver TyC</Button>
          )
        }
      </Box>,
      xs: 12
    },
    {
      source: 'default_tyc',
      value: url,
      displayNone: true,
      label: 'Terminos y condiciones',
      disabled: true,
      xs: 12, 
      validate: {
        required: 'Falta subir los Terminos y condiciones.'
      }
    },
  ];

  return data;
};

export default tycCustom;