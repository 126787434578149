import styled, { keyframes } from 'styled-components';
import { theme } from '../../../Theme/theme';
import { breakpoints } from '../../../Theme/theme';



const linear = keyframes`
    from  {
        left: 0
    }
    to {
        left: 100%
    }
`;

export const WizardButton = styled.button`
    background-color: ${props => (props.disabled && !props.small ? theme.palette.disabled.background 
    : (props.disabled && props.small) ? theme.palette.disabled.blue
      : props.enabled ? '#fff'
        : theme.palette.secondary.light
  )};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${props => (props.small ? '50px' : '70px')};
    height: ${props => (props.small ? '50px' : '70px')};
    margin: ${props => (props.selected ? '10px' : '0px')};
    border-radius: 50%;
    z-index: 6;
    border: ${props => (props.disabled ? `5px ${theme.palette.disabled.blue} solid`
    : props.enabled ? `5px ${theme.palette.secondary.light} solid`
      : props.small || props.selected || !props.disabled ? 'none' 
             
        : `5px ${theme.palette.secondary.blue} solid`
  )};
    cursor: ${props => (props.disabled ? null : 'pointer')};
    ${props => ({...props})}
   
`;

export const WizardEmpty = styled.div`
    width: 15vw;
`;
export const WizardBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // display: grid;
    // grid-template-columns: ${props => (`repeat(${props.cols}, auto)` )};
    // grid-template-rows: 1fr;
    width: 100%;
    background-color: none;
    z-index: 5;
    margin-bottom: 30px;
    overflow: hidden;
    ${props => ({...props})}
`;

export const TitleBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: start;
    background-color: none;
    ${props => ({...props})}
`;

export const WizardLine = styled.div`
    position: ${props => (props.position ? 'absolute' : 'relative')};
    top: 72px;
    z-index: 7;
    width: ${props => (props.position ? '5vw' : '7vw')};
    height: 2px;
    background-color: ${props => (props.disabled ? `${theme.palette.disabled.blue}` : `${theme.palette.secondary.light}`)};
    max-width: ${props => (props.xs ? '6vw' : (props.ls || props.length === 7) ? '4vw' : (props.length >= 8) ? '3vw' : '100px')};
    margin: 0px;
    display: ${props => (props.displayNone ? 'none' : 'block')};
    overflow: hidden;
    visibility: ${props => (!props.visibility ? 'visible' : 'hidden')}
    z-index: 800;
    border: ${props => (props.disabled ? `3px ${theme.palette.disabled.blue} solid` : `3px ${theme.palette.secondary.light} solid`)};
    ${props => ({...props})}

    
 
`;



