import {useEffect, useState} from 'react';
import { Box, Button, Collapse, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { setListSelectedIds, useDataProvider, useRecordContext, useUpdate } from 'react-admin';
import { useQuery } from 'react-query';
import { handleCreateEvent } from '../../../Components/UI/ModalEvento';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableBasic from '../../../Components/UI/Show/TableBasic';
import ModalEvento from '../../../Components/UI/ModalEvento';
import { styleTableHead, styleTabbleData } from '../../../Theme/useStyle';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { theme } from '../../../Theme/theme';
import { createNotification } from '../../helpers/helpFunctions';


const EventosCard = () => {
  const [openModal, setOpenModal] = useState(false);
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <CardBasic
        title="Eventos"
        asideAction={<CrearNuevoEvento setOpen={setOpenModal}/>}
            
      >
        <Table
            
        >
          {loading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : ( 
            record?.eventos?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell style={{...styleTableHead, textTransform: 'uppercase'}}>Título</TableCell>
                    <TableCell style={{...styleTableHead, textTransform: 'uppercase'}}>Sede</TableCell>
                    <TableCell style={{...styleTableHead, textTransform: 'uppercase'}}>Fecha</TableCell>
                    <TableCell style={{...styleTableHead, textTransform: 'uppercase'}}>Descripción</TableCell>
                    <TableCell style={{...styleTableHead, textTransform: 'uppercase'}}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    record?.eventos?.map(e => (
                      <Row loading={loading} setLoading={setLoading} record={record} row={e}/>
                    ))
                  }
                </TableBody>
              </>
            ) : (
              <TableBody>
                <Typography style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}} variant="body2">
                    No existen eventos para este socio
                </Typography>
              </TableBody>
            ))}
        </Table>
      </CardBasic>
      <ModalEvento setLoading={setLoading} record={record} open={openModal} setOpen={setOpenModal} />
    </>
  );
};


const Row = ({record, row, loading, setLoading}) => {
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [sede, setSede] = useState('');
  const [update, {loading: updateLoading, error}] = useUpdate();
  const dataProvider = useDataProvider();
  const handleDelete = () => {
    setLoading(true);
    let position =  record?.eventos.indexOf(row);
    let arr = record?.eventos;
    arr.splice(position, 1);
    dataProvider.update('socios', {data: {eventos: arr}, id: record.id})
      .then(e => {
        createNotification('success', 'Evento eliminado con éxito');
        setLoading(false);
      }
      ).catch(e => {
        createNotification('error', 'Hubo un error');
      });
  };

  useQuery(
    ['sedes_id', {id: row?.sede_id}],
    () => dataProvider.getOne('sedes', {id: row.sede_id}), 
    { 
      onSuccess: (res) => setSede(res.data.name),
      onError: (e) => { throw new Error(); }
    }
  );

  return(
    <>
      <TableRow>
        <>
          <TableCell style={styleTabbleData}>{row.title}</TableCell>
          <TableCell style={styleTabbleData}>{sede}</TableCell>
          <TableCell style={styleTabbleData}> {new Date(row.date_created).toLocaleDateString()} </TableCell>
          <TableCell   style={{...styleTabbleData, padding: '0 0 0 15px', color: theme.palette.primary.main}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell style={{...styleTabbleData, alignItems: 'center', padding: '0 0 0 15px', color: theme.palette.primary.main}}>
            {/* <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setEditModal(true)}
                        >
                            <EditIcon style={{color: theme.palette.primary.main, fontSize: "20px"}} />
                        </IconButton> */}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleDelete()}
            >
              <DeleteForeverIcon style={{color: 'rgb(211, 47, 47)'}} />
            </IconButton>
          </TableCell>
        </>
      </TableRow>
                   
      <TableRow>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 0'}} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <Box id="box" sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: '15px'}}>
                <TableCell style={{...styleTableHead, textTransform: 'uppercase', fontSize: '1em', margin: 0}}>Descripción: </TableCell>
                <TableCell style={styleTabbleData}>{row.description}</TableCell>
              </Box>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <ModalEvento row={row} isEdit open={editModal} setOpen={setEditModal} />
    </>
  );
};

const CrearNuevoEvento = ({setOpen}) => {
  return(
    <Button 
      variant='text' onClick={() => setOpen(true)}
      size='small' style={{fontWeight: '600', fontSize: '12px'}}
      startIcon={<PostAddIcon style={{fontSize: '20px', margin: '0 0 2px 0'}}/>}
    >
        Crear Evento
    </Button>
  );
};

export default EventosCard;

