export const snippetBeacon = () => {
  const permis = localStorage.getItem('permissions')
  !function(e,t,n){
    function a(){
      var e = t.getElementsByTagName('script')[0],n=t.createElement('script');
      n.type='text/javascript',n.async=!0,n.src='https://beacon-v2.helpscout.net',e.parentNode.insertBefore(n,e);
    }if(e.Beacon=n=function(t,n,a){
      e.Beacon.readyQueue.push({method:t,options:n,data:a});
    },n.readyQueue=[],'complete'===t.readyState)return a();
    e.attachEvent?e.attachEvent('onload',a):e.addEventListener('load',a,!1);
  }(window,document,window.Beacon||function(){});
  window.Beacon('init', `${permis === 'accesos' ? '415fb5e7-feb2-4b23-911b-15a8b438d28d' : '5c7cde5b-a55a-498f-8c63-e22541e4bb09'}`);
};