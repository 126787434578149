import React from 'react';
import FieldForm from '../FieldForm/FieldForm';
import { Box, Tooltip, Checkbox, Button, Grid, Switch, MenuItem, Select, Typography, useMediaQuery, FormControlLabel, FormGroup, Radio, Skeleton, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useForm , Controller} from 'react-hook-form';
import TextInputIcon, { TextInputSimple, TextAutocomplete, TextSelect, TextMultimpleAutoComplete, RadioSelect} from '../TextInputIcon/TextInputIcon';
import { Title } from './StyledComponets.js/SectionTabComponents';
import { useContext } from 'react';
import { DispatchErrorContext } from '../../Context/menuContext';
import { useEffect } from 'react';
import { theme } from '../../Theme/theme';
import { createNotification } from '../../Resources/helpers/helpFunctions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
  
const SectionTabForm = ({ children, data, isEdit, control, errors}) => {

  const { getIsDisabled, dispatchField, errors: fieldErrors, setIsLoading, setDisableSaveButton } = useContext(DispatchErrorContext);
  
  const { setValue, getValues, formState } = useForm();
  
  return(
    <>
      <Box sx={{ display: 'flex'}}>
        <Grid container rowSpacing={2} columns={{xs: 4, sm: 8, md: 12}} columnSpacing={2}>
          {
            data && data.map(item => (
              item?.displayNone ? (
                <Controller
                  control={control} 
                  rules={item.validate}
                  name={item.source}
                  defaultValue={item.defaultValue}
                  render={({field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState: {errors}}) => (
                    <input style={{display: 'none'}} value={value} />
                  )}
                />
              ) :
                item?.type === 'autocomplete' ? (
                  <>
                    <ComponentTooltip tooltip={item.tooltip} placement={item.placement}>

                      <Grid item xs={item.xs}>
                        { item.title && <Title>{item.title}</Title> }
                        <Controller
                          control={control} 
                          rules={item.validate}
                          name={item.source}
                          defaultValue={(item.initialValue && (item.initialValue[item.selectedValue] || item.initialValue)) ?? (item.defaultValue && item.defaultValue)}
                          render={({field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: {defaultValues},}) => (
                            <TextAutocomplete 
                              {...item}
                              label={item.label}
                              initialValue={item.initialValue}
                              setValue={setValue}
                              choices={item.choices}
                              error={error}
                              optionLabel={item.optionLabel}
                              defaultValue={item.defaultValue ?? defaultValues[name]}
                              icon={item.icon} 
                              color={item.color}
                              isTouched={isTouched}
                              variant={item.variant}
                              onBlur={onBlur} // notify when input is touched
                              onChange={(e) => {
                                onChange(e);
                                item.onChange && item.onChange(e);
                              }} // send value to hook form
                              inputRef={ref}
                              helperText={error?.message || item.helperText}
                            />
                          )}
                        />
                              
                      </Grid>
                    </ComponentTooltip>
                  </>
                ) : item?.type === 'multiple_autocomplete' ? (
                  <>
                    <Grid item xs={item.xs}>
                      { item.title && <Title>{item.title}</Title> }
                      <Controller
                        control={control} 
                        rules={item.validate}
                        name={item.source}
                        defaultValue={(item.initialValue && (item.initialValue[item.selectedValue] || item.initialValue)) ?? (item.defaultValue && item.defaultValue)}
                        render={({field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState: {defaultValues},}) => (
                          <TextMultimpleAutoComplete 
                            {...item}
                            label={item.label}
                            initialValue={item.initialValue}
                            setValue={setValue}
                            choices={item.choices}
                            error={error}
                            optionLabel={item.optionLabel}
                            icon={item.icon} 
                            color={item.color} 
                            variant={item.variant}
                            onBlur={onBlur} // notify when input is touched
                            onChange={(e) => {
                              onChange(e);
                              item.onChange && item.onChange(e);
                            }} // send value to hook form
                            inputRef={ref}
                            helperText={error?.message}
                          />
                        )}
                      />
                              
                    </Grid>
                  </>
                ) : item?.type === 'skeleton' ? (
                  <>
                    <Grid item xs={item.xs}>
                      { item.title && <Title>{item.title}</Title> }
                      <Skeleton style={{height: '50px', ...item?.style}}/>
                    </Grid>
                  </>
                ) : item?.type === 'radioSelect' ? (
                  <>
                    <Tooltip title={item.tooltip}>
                      <Grid item xs={item.xs}>
                        { item.title && <Title>{item.title}</Title> }
                        <>
                          <RadioSelect 
                            {...item}
                            disabled={item.disabled}
                            source={item.source}
                            label={item.label}
                            initialValue={item.initialValue}
                            choices={item.choices}
                            error={item.error}
                            optionLabel={item.optionLabel}
                            icon={item.icon} 
                            color={item.color} 
                            variant={item.variant}
                            onChange={(e) => {
                              item.onChange && item.onChange(e);
                            }}
                            inputRef={item.ref}
                          />
                        </>  
                      </Grid>
                    </Tooltip>
                  </>
                ) : item?.type === 'datePicker' ? (
                  <>
                    <Grid item xs={item.xs}>
                      { item.title && <Title>{item.title}</Title> }
                      <Controller
                        control={control} 
                        rules={item.validate}
                        name={item.source}
                        defaultValue={(item.initialValue && (item.initialValue[item.selectedValue] || item.initialValue)) ?? (item.defaultValue && item.defaultValue)}
                        render={({field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState: {defaultValues},}) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              {...item}
                              variant={item.variant}
                              renderInput={(params) => 
                                <TextField 
                                  fullWidth
                                  error={error}
                                  helperText={error?.message}
                                  variant={item.variant ? item.variant : 'outlined'}
                                  {...params}
                                />}
                            />
                          </LocalizationProvider>
                        )}
                      />
                                
                    </Grid>
                  </>
                ) : item?.type === 'button' ? (
                  <>
                    <Grid item xs={item.xs}>
                      { item.title && <Title>{item.title}</Title> }
                      <Button
                        size='small'
                        fullWidth
                        variant='contained'
                        onClick={() => item.onClick()}
                        {...item}
                      >
                        {item.label}
                      </Button>
                    </Grid>
                  </>
                ) : item?.type === 'boolean' ? (
                  <>
                    <Grid item xs={item.xs} sx={{display: 'flex', flexDirection: 'row'}}>
                      { item.title && <Title>{item.title}</Title> }
                      <Controller
                        control={control} 
                        rules={item.validate}
                        name={item.source}
                        defaultValue={item.initialValue && item.initialValue}
                        render={({field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState: {defaultValues},}) => (
                          <FormGroup>
                            <FormControlLabel
                              labelPlacement={item.labelPlacement} 
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                ...item.style}} 
                              label={item.label} 
                              control={
                                <Switch 
                                  checked={item.boolean ?? item.checked}
                                  defaultChecked={item.defaultChecked}
                                  initialValue={item.defaultChecked}
                                  defaultValue={item.defaultChecked}
                                  onChange={(e) => {
                                    onChange(e);
                                    item.onChange && item.onChange(e);
                                  }}
                                  disabled={item.disabled}
                                  onBlur={onBlur}
                                />
                              }/>
                          </FormGroup>
                        )}
                      />
                      {
                        item.tooltip && (
                          <Tooltip disabled title={item.tooltip} placement="top">
                            <Box>
                              <AiOutlineQuestionCircle size="20px" />
                            </Box>
                          </Tooltip>
                        )
                      }
                    </Grid>
                  </>
                ): item?.type === 'radio' && item.tooltip ? (
                  <>
                    <ComponentTooltip tooltip={item.tooltip} placement={item.placement}>
                      <Grid item xs={item.xs}>
                        { item.title && <Title>{item.title}</Title> }
                        <Controller
                          control={control} 
                          rules={item.validate}
                          name={item.source}
                          defaultValue={(item.initialValue && (item.initialValue[item.selectedValue] || item.initialValue)) ?? (item.defaultValue && item.defaultValue)}
                          render={({field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: {errors, defaultValues}}) => (
                            <TextInputSimple 
                              {...item}
                              name={name}
                              dependantValue={item.dependantValue}
                              value={item.dependantValue ? item.dependantValue : item.value}
                              isDirty={isDirty}
                              setValue={setValue}
                              defaultValue={item.defaultValue ?? defaultValues[name]}
                              icon={item.icon} 
                              color={item.color}
                              variant={item.variant}
                              onBlur={(e) =>{ onBlur(e); item.checkOnblur && item.checkOnblur(e);}}
                              onChange={(e) =>{ onChange(e); item.onChange && item.onChange(e);}}
                              isTouched={isTouched}
                              inputRef={ref}
                              error={item.dependantValue ? fieldErrors.find(err => err === item.source) : error}
                              helperText={(item.dependantValue && fieldErrors.find(err => err === item.source)) ? `El ${item.source} generado ya existe` : (item.dependantValue && !fieldErrors.find(err => err === item.source)) ? '': error?.message ?? item?.placeholderText}
                            />
                          )}
                        />
                              
                      </Grid>
                    </ComponentTooltip>
                  </>
                )
                  : item?.type === 'checkbox' ? (
                    <>
                      <Grid item xs={item.xs}>
                        { item.title && <Title style={{color: 'black'}}>{item.title}</Title> }
                        <Controller
                          control={control} 
                          rules={item.validate}
                          name={item.source}
                          value={item.value}
                          defaultValue={item.initialValue ? item.initialValue : item.defaultValue}
                          render={({field,
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: {defaultValues},}) => {
                            // console.log('field value', field.value);
                            return (

                              <FormGroup>
                                {item?.options?.map(check => (
                                  <>
                                    <FormControlLabel
                                      disabled={item.disabled}
                                      control={
                                        <Checkbox 
                                          {...check} 
                                          {...field} 
                                          defaultChecked={check?.defaultChecked}
                                          value={check?.checked} 
                                          disabled={check?.disabled} 
                                          onClick={(e) => {field.onChange(e); check?.onChange(e);}}
                                        />} 
                                      label={<Typography style={{...item?.checkboxLabelStyle, fontSize: '14px'}} disabled={check?.disabled}>{check?.label}</Typography>} 
                                    />
                                
                                  </>
                                ))}
                                {error && (
                                  <FormHelperText style={{color: theme.palette.error.light}}>{error?.message}</FormHelperText>
                                )}
                              </FormGroup>
                            );
                          }}
                        />
                      </Grid>
                    </>
                  ) : item?.type === 'select' ? (
                    <>
                      <Grid item xs={item.xs}>
                        { item.title && <Title>{item.title}</Title> }
                        <Controller
                          control={control} 
                          rules={item.validate}
                          name={item.source}
                          defaultValue={item.optionLabel ? item.defaultValue?.[item.optionLabel] : item.defaultValue}

                          render={({field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: {defaultValues},}) => (
                            <TextSelect
                              {...item}
                              isTouched={isTouched}
                              optionLabel={item.optionLabel}
                              optionValue={item.optionValue}
                              defaultValue={item.defaultValue ? item.defaultValue : defaultValues[name]}
                              
                              name={name}
                              isDirty={isDirty}
                              initialValue={item.initialValue && item.initialValue}
                              error={error}
                              icon={item.icon} 
                              color={item.color} 
                              variant={item.variant}
                              onBlur={onBlur} // notify when input is touched
                              onChange={(e) => {onChange(e); item.onChange && item.onChange(e);}} // send value to hook form
                              inputRef={ref}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : item?.tooltip ? (
                    <>
                      <ComponentTooltip tooltip={item.tooltip} placement={item.placement}>
                        <Grid item style={item?.gridStyle} xs={item?.xs}>
                          {item.titleSpace && <Title><br /></Title>}
                          { item.title && <Title style={{color: 'black'}}>{item.title}</Title> }
                          <Controller
                            control={control} 
                            rules={item.validate}
                            name={item.source}
                            defaultValue={item.initialValue ? item.initialValue : item.defaultValue ? item.defaultValue : null}
                            render={({field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState: {errors, defaultValues}}) => (
                              <TextInputSimple 
                                {...item}
                                name={name}
                                dependantValue={item.dependantValue}
                                value={item.dependantValue ? item.dependantValue : item.value}
                                isDirty={isDirty}
                                setValue={setValue}
                                defaultValue={item.defaultValue ?? defaultValues[name]}
                                icon={item.icon} 
                                color={item.color}
                                variant={item.variant}
                                onBlur={(e) =>{ onBlur(e); item.checkOnblur && item.checkOnblur(e);}}
                                onChange={(e) =>{ onChange(e); item.onChange && item.onChange(e);}}
                                isTouched={isTouched}
                                inputRef={ref}
                                error={item.dependantValue ? fieldErrors.find(err => err === item.source) : error}
                                helperText={(item.dependantValue && fieldErrors.find(err => err === item.source)) ? `El ${item.source} generado ya existe` : (item.dependantValue && !fieldErrors.find(err => err === item.source)) ? '': error?.message ?? item?.placeholderText}
                              />
                            )}
                          />
                              
                        </Grid>
                      </ComponentTooltip>
                    </>
                  ) : item?.type === 'autocomplete' && item.tooltip ? (
                    <>
                      <ComponentTooltip tooltip={item.tooltip} placement={item.placement}>
                        <Grid item xs={item.xs}>
                          { item.title && <Title>{item.title}</Title> }
                          <Controller
                            control={control} 
                            rules={item.validate}
                            name={item.source}
                            defaultValue={(item.initialValue && (item.initialValue[item.selectedValue] || item.initialValue)) ?? (item.defaultValue && item.defaultValue)}
                            render={({field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState: {defaultValues},}) => (
                              <TextAutocomplete 
                                {...item}
                                label={item.label}
                                initialValue={item.initialValue}
                                setValue={setValue}
                                choices={item.choices}
                                error={error}
                                optionLabel={item.optionLabel}
                                defaultValue={item.defaultValue ?? defaultValues[name]}
                                icon={item.icon} 
                                color={item.color}
                                isTouched={isTouched}
                                variant={item.variant}
                                onBlur={onBlur} // notify when input is touched
                                onChange={(e) => {
                                  onChange(e);
                                  item.onChange && item.onChange(e);
                                }} // send value to hook form
                                inputRef={ref}
                                helperText={error?.message || item.helperText}
                              />
                            )}
                          />
                              
                        </Grid>
                      </ComponentTooltip>
                    </>
                  ) : item?.divider ? (
                    <>
                      <Box style={{width: '100%', margin: '10px', borderBottom: `1px ${theme.palette.grey[300]} solid`}} />
                    </>
                  ) : item?.custom ? (
                    <>
                      <Grid item xs={item?.xs} style={item?.gridStyle}>
                        {item.title && <Title style={{color: 'black'}}>{item.title}</Title> }
                        {item?.component}
                      </Grid>
                    </>
                  ) : (!item || item === null) ? (
                    <>
                    </>
                  ) 
                    : (
                      <>
                        <Grid item {...item.gridStyle} xs={item?.xs}>
                          { item.title && <Title>{item.title}</Title> }
                          {item.titleSpace && <Title><br /></Title>}
                          <Controller
                            control={control} 
                            rules={item.validate}
                            name={item.source}
                            defaultValue={item.initialValue ? item.initialValue : item.defaultValue ? item.defaultValue : null}
                            render={({field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState: {errors, defaultValues}}) => (
                              <TextInputSimple 
                                {...item}
                                name={name}
                                isDirty={isDirty}
                                initialValue={item.initialValue && item.initialValue}
                                defaultValue={item.defaultValue ?? defaultValues[name]}
                                icon={item.icon} 
                                color={item.color}
                                variant={item.variant}
                                onBlur={(e) =>{ onBlur(e); item.checkOnblur && item.checkOnblur(e);}} // notify when input is touched
                                onChange={(e) =>{ 
                                  onChange(e); 
                                  item.onChange && item.onChange(e);
                                  (isEdit && item.checkOnblur) && setDisableSaveButton(true);
                                }}
                                isTouched={isTouched}
                                inputRef={ref}
                                error={error}
                                invalid={invalid}
                                helperText={error?.message}
                              />
                            )}
                          />
                              
                        </Grid>
                      </>
                    )
            ))
          }
        </Grid>
      </Box>
    </>
  );
};
  
export default SectionTabForm;

const ComponentTooltip = ({children, tooltip, placement = 'right'}) => {
  
  return (
    <Tooltip placement={placement} title={tooltip} style={{width: '100%'}}>
      {children}
    </Tooltip>
  );
};