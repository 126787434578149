import { Tooltip } from '@mui/material';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import { theme } from '../../Theme/theme';

const buttonForm =  {
  borderRadius: '8px',
  minHeight: 'auto',
  minWidth: '100%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  textAlign: 'left',
  justifyContent: 'flex-start',
  textTransform: 'none',
  '& > span': {
    marginRight: '14px'
  },
};

const styleActive = {
  color: theme.palette.primary.main,
  background: 'linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(220,220,220,1) 50%, rgba(220,220,220,1) 100%)',


};

const styleBox = {
  display: 'flex', 
  flexDirection: 'column',
  minWidth: '33%',
  padding: '24px',
  borderRight: '1px solid #EEEEEE'
};

const CustomFormTabs = ({tabs, url, setErrors, errors}) => {
  const redirect = useRedirect();
  const [active, setActive] = useState(0);
  const methods = useForm();
  const { formState: {errors: formErrors}} = useForm()
  
  useEffect(() => {
     setErrors(formErrors);
  }, [formErrors]); 

  const handleClick = (index) => {
    setActive(index);
    if(index == 0){
      redirect(url);
    }else{
      redirect(url +'/'+ index);
    }
  };

  return(
   

    <Box style={styleBox}>
      {
        tabs.map((item, index) => {
          let stylesButton = index == active ? {...buttonForm, ...styleActive} : buttonForm;
          stylesButton = {...stylesButton, color: item.disabled ?  '#BDBDDF' : '#000'};
          if(!item) return;
          else if(item.tooltip){
            return(
              <Tooltip title={item.tooltip} placement='top'>
                <Box>
                  <Button 
                    disabled={item.disabled}
                    style={stylesButton} 
                    startIcon={item.icon} 
                    onClick={() => handleClick(index)} 
                    key={item.label+index}
                  >
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </Button>
                </Box>
              </Tooltip>
            );
          }else{
            return(
              <Button 
                disabled={item.disabled}
                style={stylesButton} 
                startIcon={item.icon} 
                onClick={() => handleClick(index)} 
                key={item.label+index}
              >
                <Typography variant='subtitle2'>{item.label}</Typography>
              </Button>
            );
          }
        }
        )
      }
    </Box>

  );
};

export default CustomFormTabs;