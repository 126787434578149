import { Datagrid, List, TextField } from 'react-admin';
import { styled } from '@mui/system';
import { ImageField } from 'react-admin';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';

const defaultFilter = [
  <TextInputIcon
    source="name" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
];

export const AmenitiesList = props => {
  return(
    <List 
      bulkActionButtons={false}
      perPage={25} 
      filters={defaultFilter}
      {...props}
    >
      <DatagridCustom rowClick='show'>
        <IconSmall source="icon.url" label='Icono'/>
        <TextField source="name" label='Nombre'/>
        <TextField source='slug'/>
      </DatagridCustom>
    </List>
  );
};

const IconSmall = styled(ImageField)({
  img:{width: '26px'}
});