
import {styled } from '@mui/system';
import { theme } from '../../../Theme/theme';
import { DeleteWithConfirmButton, SaveButton } from 'react-admin';
import { Button } from '@mui/material';

export const ButtonToolbar = styled(Button)({
  fontSize: theme.typography.fontSize,
  padding: '9px 26px',
  textTransform: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  justifySelf: 'flex-start',
  fontWeight: theme.typography.fontWeightBold,
  width: '100%',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.dark
  },
  '& > span > svg': {
    fontSize: '18px !important'
  }
});
