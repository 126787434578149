import { usePermissions, useRecordContext } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import aplicationFee from '../Edit/AplicationFee';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';

const AplicationCard = () => {

  const record = useRecordContext();
  const permis = usePermissions().permissions;
  const notOwner = permis === 'owner' || permis === 'superadmin';
  const central =  record?.negocio_central;
  
  const editComponent = {
    component: aplicationFee(central),
    label: 'Comisiones',
    icon: null
  };
  
  const data = [
    {key: 'Mensual', value: `$ ${record?.application_fee?.mensual ?? 0}`},
    {key: 'Trimestral', value: `$ ${record?.application_fee?.trimestral ?? 0}`},
    {key: 'Cuatrimestral', value: `$ ${record?.application_fee?.cuatrimestral ?? 0}`},
    {key: 'Semestral', value: `$ ${record?.application_fee?.semestral ?? 0}`},
    {key: 'Anual', value: `$ ${record?.application_fee?.anual ?? 0}`}
  ];

  return(
    <>
      <CardBasic title={'Comisiones'} route={'merchants'} editComponent={notOwner && editComponent} record={record} >
        <TableMinimal data={data}/>
      </CardBasic>
    </>
  );
};

export default AplicationCard;