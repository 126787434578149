import {useEffect, useState} from 'react';
import { Box } from '@mui/material';
import CardDashboard from './Card/CardDashboard';
import CardContentSmall from './Card/CardContentSmall';
import { Spinner } from './Spinner/Spinner';

const mockMedios = {
  title: 'Altas',
  datasets: [
    {
      key: 'Mercado Pago',
      value: Math.floor(Math.random() * 30)
    },
    {
      key: 'Decidir',
      value: Math.floor(Math.random() * 30)
    },
    {
      key: 'Efectivo',
      value: Math.floor(Math.random() * 30)
    }
  ]
};

const PlanesSmallCard = ({noPad, ventasMensuales, date, isFetchingVentas}) => {
  const [datasets, setDatasets] = useState({});
  const helpText = 'Monto cobrado segmentado por el nivel de acceso del plan.';

  useEffect(() => {
    let data = ventasMensuales?.data?.json?.data;
    setDatasets({datasets: []});
    let newArr = [];
    let otros = 0;
  
    data && 
  data?.
    planes?.
    sort((a, b) => a.cantidad > b.cantidad ? 1 : -1).
    reverse().
    map(e => {
      if(newArr.length < 4 && e?.nombre !== 'Otros') {
        
        newArr.push({
          key: e?.tipo,
          value: e?.monto,
          total: e?.cantidad
        });
      } else {
        otros = otros + e?.cantidad;
      }
    });
    // data && newArr.length > 0 && newArr.push({ key: 'Otros', value: otros});
    setDatasets({datasets: newArr});
  }, [ventasMensuales, date]);

  return(
    <>
      <CardDashboard noPad>
        <CardContentSmall planes isFetching={isFetchingVentas} helpText={helpText} title={'Cobros por nivel de acceso'} data={datasets} />
      </CardDashboard>
    </>
  );
};

export default PlanesSmallCard;