
import React from 'react';
import { useRecordContext } from 'react-admin';
import { RiFilePaper2Line } from 'react-icons/ri';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import tycCustom from '../Edit/TycCustom';

const TyCMerchantCard = () => {
  const record = useRecordContext()
  const data = [
    {key: 'Ver PDF', value: record.default_tyc, link: true},
  ];

  const editComponent =  {
    component: tycCustom(),
    label: 'Términos y Condiciones',
    icon: <RiFilePaper2Line/>
  }

  

  return (
    <CardBasic route={'merchants'} title='Terminos y Condiciones' record={record} editComponent={editComponent} collapsed>
      <TableMinimal widthBoxKey='300px' data={record.default_tyc ? data : []} emptyText={'No se encuentran cargados los terminos y condiciones'}/>
    </CardBasic>
  );
};

export default TyCMerchantCard;