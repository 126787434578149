import React, {useState} from 'react';
import {  useDataProvider, useRedirect, useRecordContext } from 'react-admin';
import { BsCardText } from 'react-icons/bs';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import { RiFilePaper2Line } from 'react-icons/ri';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { BsCreditCard2Back } from 'react-icons/bs';
import InformacionMerchant from './Edit/InformacionMerchant';
import informacionMerchant from './Edit/InformacionMerchant';
import tycCustom from './Edit/TycCustom';
import TycCustom from './Edit/TycCustom';
import AfipMerchant from './Edit/AfipMerchant';
import afipMerchant from './Edit/AfipMerchant';
import planesCadena from './Edit/PlanesCadena';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { createNotification } from '../helpers/helpFunctions';
import {credentialsEdit} from './Edit/CredentialsEdit';
import CreateForm from '../../Components/Form/CreateForm';
import PaidIcon from '@mui/icons-material/Paid';
import aplicationFee from './Edit/AplicationFee';

const choicesProcessor = [
  {id: 'MercadoPago', name: 'MercadoPago'}
];

const MerchantsCreateEdit = ({isEdit, ...props}) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [central, setCentral] = useState(false);
  const record = useRecordContext();

  const successFun = (data) => {
    dataProvider.getFile('refresh_token').then(item => {
      localStorage.setItem('auth',`{"token":"${item.data.json.token}"}`);
    });
  };

  const tabs = [
    {
      component: informacionMerchant(central, setCentral),
      label: 'Información',
      icon: <BsCardText/>
    },
    {
      component: credentialsEdit(record),
      label: 'Credenciales',
      icon: <BsCreditCard2Back/>
    },
    {
      component: aplicationFee(central),
      label: 'Comisión',
      icon: <PaidIcon />
    },
    {
      component: afipMerchant(),
      label: 'AFIP',
      icon: <HiOutlineDocumentText/>
    },
    {
      component: tycCustom(),
      label: 'Términos y Condiciones',
      icon: <RiFilePaper2Line/>
    }
  ];

  const transform = (data) => {
    console.log('TRANSFORM', data.appication_fee);
    
    if(!data.afip.telefono){
      return {...data,
        afip: {
          ...data.afip,
          telefono: ''
        }
      };
    }
    return data;
  };

  return (
    <CreateForm transform={transform} tabs={tabs} route="merchants"/>
  );

};

export default MerchantsCreateEdit;