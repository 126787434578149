import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from '@blackbox-vision/ra-language-spanish';

var messages = { 
  ...spanishMessages,
  locale: 'es',
  ra: {
    ...spanishMessages.ra,
    saved_queries: {
      new_label: 'Guardar los filtros seleccionados',
      remove_label: 'Borrar el filtro: ',
      remove_dialog_title: 'Borrar filtro personalizado?',
      remove_message: '¿Estás seguro que quieres borar el filtro personalizado?',
      query_name: 'Nombre del filtro',
      remove_label_with_name: 'Borrar filtro "%{name}"',
      new_dialog_title: 'Guardar Filtros personalizados',
    }
  }
};
messages['ra']['page']['dashboard'] = 'Reportes';
messages['ra']['action']['remove_all_filters'] = 'Borrar filtros';
export const i18nProvider = polyglotI18nProvider(() => messages, 'es');