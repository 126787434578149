import React from 'react';
import { styled } from '@mui/system';
import { NumberInput, TextInput, DateInput, AutocompleteInput,
  BooleanInput, SelectInput, AutocompleteArrayInput } from 'react-admin';
  
export const InputSmall = ({source, initialValue, onChange, disabled, label, sx, validate, className, style}) => {
  return (
    <InputDisplay 
      source={source} 
      defaultValue={initialValue} 
      onChange={onChange}
      disabled={disabled}
      label={label}
      sx={sx}
      variant='outlined'
      validate={validate}
      className={className}
      style={style}
    />
  );
};

export const InputDisplay = styled(TextInput)({
  p:{display: 'none'}
});

export const NumberSmall = styled(NumberInput)({
  p:{display: 'none'}
});

export const DateSmall = styled(DateInput)({
  p:{display: 'none'}
});

export const AutoCompleteSmall = styled(AutocompleteInput)({
  p:{display: 'none'},
  'container': {
    flexGrow: 0
  }
});

export const BooleanInputSmall = styled(BooleanInput)({
  p:{display: 'none'}
});

export const SelectSmall = styled(SelectInput)({
  p:{display: 'none'}
});

export const AutocompleteArrayInputSmall = styled(AutocompleteArrayInput)({
  p:{display: 'none'}
});