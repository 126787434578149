import React from 'react';
import { Create, useDataProvider } from 'react-admin';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import {FormProvider, useForm} from 'react-hook-form';
import MerchantsCreateEdit from './MerchantsCreateEdit';

const MerchantsCreate = (props) => {

  const methods = useForm();
  return (
    <>
      <FormProvider {...methods}>
        <Create
          {...props}
          mutationMode="pessimistic" 
          title={<TitleEdit title='Crear Negocio'/>}
        >
          <MerchantsCreateEdit />
        </Create>
      </FormProvider>
    </>
  );
};

export default MerchantsCreate;

