import React, { useState } from 'react';
import { Avatar, Box, Link, Typography } from '@mui/material';
import ModalImage from '../ModalImage';

const ImageComponent = ({item, hiddenUrl, small}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  
  return(
    <Box key={item?.url} style={{cursor: 'pointer'}}>
      <Link href={item?.url} target="_blank" rel="noopener">
        <Box 
          style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
          src={item?.url} target='_blank'
        >
          <Avatar
            alt="Image Plan"
            src={item?.url ? item.url : item}
            target='_blank'
            style={small ? 
              { width: '70px', height: '70px', borderRadius: '10px' } :
              { width: '110px', height: '110px', borderRadius: '10px'}}
          />
        </Box>
      </Link>
      <ModalImage open={open} handleClose={handleClose} url={item?.url}/>
    </Box>
  );
};

export default ImageComponent;