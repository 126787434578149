import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const MenuActionsDiv = styled('div')({
  position: (props) => {
    return props.position ? props.position : 'static';
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  transition: 'display 2s',
});

export const ActionsDiv = ({children, sx}) => {
  return (
    <Box
      sx={{
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate(-80%, -50%)',
        display: 'flex',
        zIndex: 3333,
        overflow: 'visible',
        width: '180px',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        border: '1px solid rgb(212,212,232)',
        borderRadius: '5px',
        padding: '9px 6px',
        transition: 'display 2s',
        cursor: 'pointer',
        ...sx
      }}
    >
      {children}
    </Box>
  );
};