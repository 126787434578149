import { Box, Modal } from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: 'none'
};

const styleImage = {
  objectFit: 'cover',
  width: '70vw'
};

const ModalImage = ({open, handleClose, url, alt}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{border:'none'}}
    >
      <Box style={style}>
        <img src={url} alt={alt ? alt : 'Imagen no disponible'} style={styleImage}/>
      </Box>
    </Modal>
  );
};

export default ModalImage;