import { Box, Button, CircularProgress, IconButton, Modal, Typography } from '@mui/material';
import { fontTitleHeader } from '../../Theme/useStyle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ModalCustom from '../UI/ModalCustom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  minHeight: '150px',
  maxHeight: '900px',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: 24,
};
  
const styleBoxHead = {
  display: 'flex', 
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px'
};

const ConfirmModal = ({ open, setOpen, setConfirm, onClose, loading, disabledAction, onClickAction, textAction, confirm, children}) => {

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <Box style={styleBoxHead}>
          <Typography style={{...fontTitleHeader, padding: '20px'}}>
              Confirmacion
          </Typography>
          <IconButton aria-label="delete" onClick={onClose ? onClose : () => handleClose()} >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        <Box style={{padding:'10px 30px', alignItems: 'center'}}>
          {children}
        </Box>
        <Box style={{
          display: 'flex', 
          justifyContent: 'space-around', 
          justifySelf: 'end',
          position: 'relative',
          width:'100%',
          bottom: 0
        }}>
          <div >
            <Button 
              style={{margin: '20px', borderRadius: '30px'}}
              variant='contained'
              color={'error'}
              onClick={handleClose}
            >
              {'Cancelar'}
            </Button>
          </div>
          <div >
            <Button 
              style={{margin: '20px', borderRadius: '30px'}}
              variant='contained'
              color={'primary'}
              onClick={onClickAction}
              disabled={disabledAction}
              startIcon={loading && <CircularProgress style={{width: '20px', height: '20px'}}/>}
            >
              {textAction ? textAction : 'Guardar'}
            </Button>
          </div>
                
                  
        </Box>
      </Box>
    </Modal>
        
  );
};

export default ConfirmModal;