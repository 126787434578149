import React, { useState,useContext, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { Button, Box, Card,IconButton,Tooltip } from '@mui/material';
import {useRedirect,usePermissions, useSidebarState} from 'react-admin';
import {stepsitos} from '../../Assets/Constants/stepsJoyRide';
import { FiBookOpen } from 'react-icons/fi';
import { merchantContext } from '../../DataProvider';
import { theme } from '../../Theme/theme';

const JoyRide = ({setMovementScroll,index,setIndex}) => {
  const { getMerchant } = useContext(merchantContext);
  const permis = usePermissions().permissions;
  const merchant = getMerchant();
  const redirect = useRedirect();
  const [name,setName]= useState(1);
  const [state, setState] = useState({
    run: false,
    steps: stepsitos(permis,merchant)
  });
  const sidebarOpen = useSidebarState();
  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    setIndex(index);
    
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));
    };
    if(index === 9){
      redirect('/planes');
      setIndex(index);
    }
    if(index === 12 && action == 'next'){
      if(permis === 'owner' || permis === 'superadmin' || permis === 'admin')
        redirect('/corporativo');
    }
    if(index === 7 && action == 'prev'){
      setMovementScroll(true);
    }
    if(permis === 'operador'){
      if(index === 8){
        redirect('/planes');
      }
    }
    if(index === 13 && action == 'prev'){
      if(permis === 'owner' || permis === 'superadmin' || permis === 'admin')
        redirect('/planes');
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    setName(name+1);
    setState((prevState) => ({
      ...prevState,
      run: true,
    }));
  };
  // Con esto fuerzo a que se cargue el state, ya que por si solo no detecta el cambio del merchant.
  useEffect(() => {
    if (merchant && permis === 'admin') {
      setState((prevState) => ({
        ...prevState,
        steps: stepsitos(permis,merchant)
      }));
    }
  }, [merchant]);
  return (
    <Box
      sx={{
        // width: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // marginBottom:'15px',
      }}
    >
      {/* <Card variant="outlined" raised={true} sx={{ px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'rgba(0,0,0,0.03)' }}> */}
      <Tooltip placement="bottom" title="Iniciar recorrido">
        <IconButton
          onClick={handleClick}
          size="medium"
          sx={{ marginLeft: '5px' }}
        >
          <FiBookOpen color='white'/>
        </IconButton>
      </Tooltip>
   
      {/* </Card> */}
      <Joyride
        key={name}
        locale={{
          back:'Atrás',
          skip:'Omitir',
          next:'Siguiente',
          close:'Cerrar',
          last:'Finalizar'
        }}
        disableScrolling={index > 16 ? false : true}
        continuous
        styles={{
          overlay: {
            zIndex: 100
          },
          tooltip:{
            opacity:1
          },
          options: {
            primaryColor: theme.palette.primary.main,
          },
          spotlight: {
            maxHeight:index !== 1 ? '50px' : 'auto',
          }
        }}
        callback={handleJoyrideCallback}
        run={state?.run}
        steps={state?.steps}
        scrollToFirstStep
        showSkipButton
        showProgress={true}
      />
    </Box>
  );
};

export default JoyRide;
