import React, {useContext, useState} from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useForm} from 'react-hook-form';
import { BiUser } from 'react-icons/bi';
import { BiHome } from 'react-icons/bi';
import FieldForm from '../FieldForm/FieldForm';
import { Wizard } from './Wizard';
import { TabbedForm, TabForm as StyledTabForm } from './StyledComponets.js/FormComponents';
import SectionTabForm from './SectionTabForm';
import FormTab from './FormTab';
import useCheckField from '../../Hooks/useCheckField';
import { useEffect } from 'react';
import { merchantContext } from '../../DataProvider';
import { useRefresh } from 'react-admin';
import { DispatchErrorContext } from '../../Context/menuContext';


const CreateForm = ({handleDelete, handleCancelar, tabs, transform, defaultValues, route, onSuccess, validateSubmit, isEdit}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { getMerchant, setMerchant } = useContext(merchantContext);
  const { clearAllDispatchFields, errors, getIsDisabled } = useContext(DispatchErrorContext);
  const { setValue, setFocus, reset, setError, clearErrors, handleSubmit, trigger, register, unregister, control, getValues, } = useForm({
    defaultValues: defaultValues,
    mode: 'onBlur',
  });
  const handleTab = (tab) => {
    
    setCurrentTab(tab);

  };
  useEffect(() => {
    return () => {
      clearAllDispatchFields();
    };
  },[]);

  return (
    <>
      <Box sx={{ backgroundColor: '#f1f1f1', display: 'flex', flexDirection: 'column', padding: '21px', alignItems: 'center', justifyContent: 'center'}}>
        <Wizard tabs={tabs} currentTab={currentTab} handleTab={handleTab}/>
        <FormTab
          handleDelete={handleDelete} 
          handleCancelar={handleCancelar} 
          transform={transform} 
          onSuccess={onSuccess}
          route={route} 
          tabs={tabs} 
          defaultValues={defaultValues}
          isEdit={isEdit} 
          currentTab={currentTab}
          validateSubmit={validateSubmit} 
          handleTabValid={handleTab}
        />
      </Box>
    </>
  );
};

export default CreateForm;